import axios from "axios";
import BASEURL from "../Config/global";

async function localStorageData() {
    const data = JSON.parse(localStorage.getItem("user"));
    return data;
}

async function GetAPI(url) {
    const response = await axios.get(BASEURL + url);
    return response;
}

async function PostAPI(url, data) {
    const response = await axios.post(BASEURL + url, data);
    return response;
}

export { GetAPI, PostAPI, localStorageData }; 