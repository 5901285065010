import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { playAudio } from '../../Store/Slices/SoundPlayerSlice';

import BASEURL from '../../Config/global';

import { FillPlay, FillPause, TrackedPlay } from '../../Assets/svg';

import { pauseMixer, playMixer } from '../../Store/Slices/MixerSlice';
import { Link } from 'react-router-dom';
import { activeUser } from '../../Store/Slices/APIs';

const AudioStrip = ({ item }) => {

    const dispatch = useDispatch();
    const audio = useSelector((state) => state.soundPlayer.audio);
    const isPlaying = useSelector((state) => state.mixer.play);

    const [isPlayingState, setIsPlayingState] = useState(false)
    const [isActiveAudio, setIsActiveAudio] = useState(false)

    useEffect(() => {
        if (audio.audio == item.audio) {
            setIsActiveAudio(true)
        }
        else {
            setIsActiveAudio(false)
        }
    }, [audio])

    useEffect(() => {
        if (audio.audio == item.audio && isPlaying) {
            setIsPlayingState(true)
        }
        else {
            setIsPlayingState(false)
        }
    }, [audio])

    useEffect(() => {
        if (isPlaying) {
            setIsPlayingState(true)
        }
        else {
            setIsPlayingState(false)
        }
    }, [isPlaying])

    const handlePlay = () => {
        if (isActiveAudio) {
            if (isPlaying) {
                dispatch(pauseMixer());
                setIsPlayingState(false);
            } else {
                dispatch(playMixer());
                setIsPlayingState(true);
            }
        } else {
            dispatch(playAudio(item));
            dispatch(playMixer());
            setIsPlayingState(true);
            dispatch(activeUser());
        }
    };


    return (
        <div className="audioStrip">
            <Link
                to={`/audio-detail/${item.type}/${item.id}`}
                className="audioStripDetails">
                <img src={`${item?.thumbnail}`} alt="" />
                <p>{item?.title}</p>
            </Link>
            <div className="audioStripExtra">
                <p className="audioStripduration">{item?.tag && item?.tag}</p>
                <button
                    type="button"
                    className="audioStripButton audioStripPlay"
                    onClick={handlePlay}
                >
                    {isPlayingState && isActiveAudio ? (
                        <img src={FillPause} />
                    ) : (
                        <img src={TrackedPlay} />
                    )}
                </button>
            </div>
        </div>
    )
}

export default AudioStrip
