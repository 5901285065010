import React from 'react'

const Loader = () => {
    return (
        <button class="primaryButton customButton">
            <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
        </button>
    )
}

export default Loader