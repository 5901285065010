import { createSlice } from "@reduxjs/toolkit";
import BASEURL from "../../Config/global";

const soundPlayerSlice = createSlice({
  name: "soundPlayer",
  initialState: {
    sounds: [],
    mix: [],
    mixObj: {},
    music: {},
    currentSoundsList: [],
    checkPlayingSound: false,
    soundRemoval: null,
    audio: {},
    errorMessage: false,
    allSounds: [],
    allSoundsRun: false,
    clicklistner: true,
    checkSoundPlayerOrMixesState: false,
    isMixes: false,
    isClearMixes: false,
    isSoundPlay: false,
  },
  reducers: {
    playMyMixer(state, action) {
      console.log(action.payload)
      state.mix = action.payload;
    },
    removeMix(state, action) {
      state.mix = [];
      state.mixObj = {};
    },
    playSound(state, action) {
      console.log("sound")
      const soundExists = state.sounds.some(
        (sound) => sound.id === action.payload.id
      );
      if (!soundExists) {
        localStorage.removeItem("saveMix");
        state.sounds.push(action.payload);
      }
    },
    playAudio(state, action) {
      console.log("audio")
      if (state.audio?.audio == action.payload.audio) {
        return;
      } else {
        localStorage.removeItem("saveMix");
        state.checkSoundPlayerOrMixes = false;
        state.audio = action.payload;
      }
    },
    playMusic(state, action) {
      state.music = action.payload;
    },
    playMyMixes(state, action) {
      state.isMixes = true;
      state.isClearMixes = false;
    },
    playMyMixerObj(state, action) {
      state.mixObj = action.payload;
    },
    removeSound(state, action) {
      const item = action.payload;
      state.soundRemoval = item
      state.sounds.forEach((sound, index) => {
        if (item.id == sound.id) {
          state.sounds.splice(index, 1)

          return
        }
      })
      localStorage.removeItem("saveMix");
    },
    emptySoundRemoval(state, action) {
      state.soundRemoval = null
    },
    removeAudio(state, action) {
      state.audio = {};
      localStorage.removeItem("saveMix");
    },
    removeMusic(state, action) {
      state.music = {};
      localStorage.removeItem("saveMix");
    },
    clearAllSound(state, action) {
      state.sounds = [];
      state.audio = {};
      state.music = {};
      localStorage.removeItem("saveMix");
    },
    clearAllMixer(state, action) {
      console.log("clear mixer.. ")
      state.isClearMixes = true;
    },
    startSounds(state, action) {
      // state.isClearMixes = true;
      state.isMixes = false;
      state.isSoundPlay = true;
    },
    hideErrorMessage(state) {
      state.errorMessage = false;
    },
    selectedSound(state, action) {
      const soundExists = state.sounds.some(
        (sound) => sound.audio === action.payload.audio
      );
      if (!soundExists) {
      }
    },
    clickListner(state, action) {
      console.log(action.payload)
      // state.clicklistner = action.payload
    },
    checkSoundPlayerOrMixes(state, action) {
      state.checkSoundPlayerOrMixesState = action.payload;
    }
  },
});

export const {
  playMyMixer,
  playSound,
  playMyMixerObj,
  playAudio,
  playMusic,
  playMyMixes,
  pauseMyMixes,
  removeMusic,
  startSounds,
  stopSounds,
  removeMix,
  // setVolume,
  // playAll,
  // pauseAll,
  // setSoundVolume,
  // pauseSound,
  // stopSound,
  removeSound,
  emptySoundRemoval,
  removeAudio,
  clearAllSound,
  clearAllMixer,
  hideErrorMessage,
  selectedSound,
  clickListner,
  checkSoundPlayerOrMixes
} = soundPlayerSlice.actions;

export default soundPlayerSlice.reducer;
