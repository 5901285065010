import React from 'react'
import { logo } from "../../Assets/images";
import { Link } from 'react-router-dom';
import { NoInternet } from '../../Assets/svg';
import './style.css'

const NoInternetConnection = () => {
    return (
        <section className="mainWrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="mainCategories pt-5 px-0">
                            <div className="logo mb-5 mt-2 mt-sm-5 pt-5">
                                <img src={logo} alt="Logo" />
                            </div>
                            <div className="mt-5 d-flex flex-column align-items-center justify-content-center text-center">
                                <svg fill="#fff" width="50px" height="50px" viewBox="0 0 36 36">
                                    <path d="M18,2A16,16,0,1,0,34,18,16,16,0,0,0,18,2Zm0,30A14,14,0,1,1,32,18,14,14,0,0,1,18,32Z" className="clr-i-outline clr-i-outline-path-1"></path>
                                    <path d="M27.15,15H8.85A1.85,1.85,0,0,0,7,16.85v2.29A1.85,1.85,0,0,0,8.85,21H27.15A1.85,1.85,0,0,0,29,19.15V16.85A1.85,1.85,0,0,0,27.15,15Zm.25,4.15a.25.25,0,0,1-.25.25H8.85a.25.25,0,0,1-.25-.25V16.85a.25.25,0,0,1,.25-.25H27.15a.25.25,0,0,1,.25.25Z" class="clr-i-outline clr-i-outline-path-2"></path>
                                    <rect x="0" y="0" width="36" height="36" fill-opacity="0" />
                                </svg>
                                <h1 className="d-flex align-items-center fw-medium mt-4 no-internet-text">No internet connection available.</h1>
                                <h1 className="d-flex align-items-center fw-medium mt-1 no-internet-subtext">Please connect to use the app.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NoInternetConnection