import React, { useEffect } from 'react'
import {  useNavigate } from 'react-router'

const RedirectToPromo = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate("/promo/" + window.location.pathname.split('/')[2])
    }, [])

     return (
        <section className="mainWrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="mainCategories justify-content-center">
                            <h2>Redirecting to Promo</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RedirectToPromo