import { useEffect, useRef, useState } from "react";
import "./style.css"
import { crossIcon, SaveShareMixEmailIcon, SaveShareMixIphoneMessageIcon, SaveShareMixLinkIcon, SaveShareMixMessenerIcon, SaveShareMixMoreIcon, SaveShareMixWhatsappIcon } from "../../Assets/svg";
import RoundAudio from "../RoundAudio";
import {
    EmailIcon,
    EmailShareButton,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import { isIOS, isMobileSafari, isSafari } from "react-device-detect";
import axios from "axios";
import BASEURL from "../../Config/global";
import { Modal } from "react-bootstrap";
import CryptoJS from 'crypto-js';
import BASEURLFrontend from "../../Config/urls";
// import BASEURLFrontend from "../../Config/global";

const ShareMixSave = ({ soundlist, handleClick, closeAllPanels, audiolist, musicList, isSaveList, id, shareMixName, shareIndividualUrl=null, shareListShowOnly=null }) => {

    const [shareUrl, setShareUrl] = useState(`${BASEURLFrontend}/share.mix`);
    const [shareMixerDataForSharing, setShareMixerDataForSharing] = useState([]);
    const [defaultOpen, setDefaultOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [copiedText, setCopiedText] = useState(false);
    const [encryptedText, setEncryptedText] = useState('');
    // const [decryptedText, setDecryptedText] = useState('');
    const [houseHoldEmailModal, setHouseHoldEmailModal] = useState(false);
    const [houseHoldEmailMsg, setHouseHoldEmailMsg] = useState({ error: false, text: '', type: '' });
    const [houseHoldSuccesModal, setHouseHoldSuccesModal] = useState(false);

    const emailRef = useRef('')


    useEffect(() => {
        // const sortName = shareMixName?.split(" ").join('-');
        encryptText(id);
        // debugger
        // const key = 'secret-key'; // Replace with your secret key
        // const encrypted = CryptoJS.AES.encrypt(id, key);
        // console.log(encrypted.toString())
        // function randomString(length, chars) {
        //     var result = '';
        //     for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        //     return result;
        // }
        // var string = randomString(4, 'abcdefghijklmnopqrstuvwxyz');
        // setEncryptedText(encrypted.toString());
        // setShareUrl(`${BASEURLFrontend}/share.mix/${sortName}/${string + id}`);
        // setShareUrl(`http://localhost:3000/share.mix/${sortName}/${string + id}`);
        // if (isSaveList) {
        //     setShareUrl(`https://relax.scofa.com/share.mix/${id}`);
        //     setDefaultOpen(true)
        // } else {
        //     setTimeout(() => {
        //         const postData = [];
        //         if (soundlist && soundlist.length > 0) {
        //             soundlist.forEach((items, index) => {
        //                 postData.push({
        //                     "audio": (isMobileSafari || isSafari || isIOS) ? items.ios_patch : items.audio,
        //                     "tumbnail": items.thumbnail,
        //                     "image": items.image,
        //                     "name": items.title,
        //                     "sound_id": items.id,
        //                     "type": "Sounds",
        //                     "narrate": items.naration
        //                 })
        //             });
        //         }
        //         if (audiolist && audiolist.audio) {
        //             postData.push({
        //                 "audio": audiolist.audio,
        //                 "tumbnail": audiolist.thumbnail,
        //                 "image": audiolist.image,
        //                 "name": audiolist.title,
        //                 "sound_id": audiolist.id,
        //                 "type": audiolist.type,
        //                 "narrate": audiolist.naration
        //             })
        //         }
        //         if (postData.length > 0) {
        //             const fetchData = async () => {
        //                 try {
        //                     const mixerPostData = {
        //                         "name": "00990066",
        //                         "sounds_list": postData
        //                     };
        //                     console.log(mixerPostData)
        //                     // const response = await axios.post(`${BASEURL}/api/sounds/sounds_mixture`, mixerPostData);
        //                     // await setShareUrl(`https://relax.scofa.com/share.mix/${response.data.data.id}`);
        //                     // setDefaultOpen(true)
        //                     // console.log(response)
        //                 } catch (error) {
        //                     console.error(error);
        //                 }

        //             }
        //             fetchData();
        //         }
        //     }, 100);
        // }
        // console.log(id)
    }, [isSaveList, id]);


    const encryptText = (no) => {
        const key = 'secret-key'; // Replace with your secret key
        const id = no.toString()
        const encrypted = CryptoJS.AES.encrypt(id, key);
        const encryptedText = encrypted?.toString()?.replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l');
        // const encryptedText = encrypted?.toString();
        const sortName = shareMixName?.split(" ").join('-');
        console.log(encryptedText.toString());
        if (shareIndividualUrl) {
            setShareUrl(`${BASEURLFrontend}/${shareIndividualUrl}/${encryptedText.toString()}?encrypted=yes`);
        } else {
            setShareUrl(`${BASEURLFrontend}/share.mix/${encryptedText.toString()}`);
        }
        // const encryptedSorted = encrypted?.toString()?.split("/").join('$');
    };

    // const decryptText = () => {
    //     const key = 'secret-key'; // Replace with your secret key
    //     const decrypted = CryptoJS.AES.decrypt(encryptedText, key);
    //     setDecryptedText(decrypted.toString(CryptoJS.enc.Utf8));
    // };


    // useEffect(() => {
    //     encryptText();
    //     decryptText();
    // }, [])




    // console.log(encryptedText, decryptedText);


    // useEffect(() => {
    //   console.log
    // }, [handleClick])


    const sortDataForShare = (soundlist) => {
        const postData = [];
        if (soundlist && soundlist.length > 0) {
            soundlist.forEach((items, index) => {
                postData.push({
                    "name": items.title,
                    "thumbnail": items.thumbnail,
                    "sounds_list": items.ios_patch,
                })
            });
            setShareMixerDataForSharing(postData)
        }
    }

    const copyURL = () => {
        // console.log("---")
        var textField = document.createElement('textarea')
        textField.innerText = shareUrl
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        setCopiedText(true);
        textField.remove();
        setTimeout(() => {
            setCopiedText(false);
        }, 1000);
    }

    const beforeClick = () => {
        if (window.innerWidth < 575) {
            handleClick();
        }
    }

    const houseHoldShare = async () => {
        console.log("===")
        setHouseHoldEmailMsg({ error: false, text: '' })
        if (emailRef.current.value.length > 0) {
            const user = JSON.parse(localStorage.getItem("user"));
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (pattern.test(emailRef.current.value)) {
                const formDataToSend = new FormData();
                formDataToSend.append("email", emailRef.current.value);
                formDataToSend.append("link", shareUrl);
                formDataToSend.append("user_id", user?.id);
                try {
                    const response = await axios.post(
                        `${BASEURL}/api/user/email_sharemix/`,
                        formDataToSend
                    );
                    if (!response.data.error) {
                        setHouseHoldSuccesModal(true);
                        setHouseHoldEmailModal(false);
                        setTimeout(() => {
                            setHouseHoldSuccesModal(false);
                            handleClick();
                        }, 4000);
                    } else {
                        setHouseHoldEmailMsg({ error: true, text: 'Please Enter a valid Email' })
                    }
                } catch (error) {
                    console.log(error)
                }
            } else {
                setHouseHoldEmailMsg({ error: true, text: 'Please Enter a valid Email' })
            }
        } else {
            setHouseHoldEmailMsg({ error: true, text: 'Please Enter Your Email' })
        }
    }

    // console.log(shareListShowOnly, soundlist)

    return (
        <>
            <div className="share-mix-popup">
                <div className="share-mix-wrapper d-flex flex-column">
                    <div className="d-flex flex-column flex-grow-1 ">
                        <div className="save-mix-header text-end">
                            <button
                                className="notButton ms-auto d-flex align-items-center p-4"
                                onClick={handleClick}

                            >
                                <img src={crossIcon} alt="Close Button" className="img-fluid" style={{ width: '15px' }} />
                            </button>
                        </div>
                        <div className="share-mix-wrapper-inner d-flex flex-column flex-grow-1">
                            <div className="flex-shrink-0">
                                <h5 className="text-center fs-3">Share your Mix</h5>
                            </div>
                            <div className="flex-shrink-0 my-4">
                                <div className="save-share-sounds">
                                    <div className="soundBoxWrapper">
                                        {
                                            shareListShowOnly?.map((item, index) => {
                                                return <RoundAudio key={index} item={item} />
                                            })
                                        }
                                        {
                                            soundlist?.map((item, index) => {
                                                return <RoundAudio key={index} item={item} />
                                            })
                                        }
                                        {
                                            musicList && musicList.hasOwnProperty('id') && <RoundAudio item={musicList} />
                                        }
                                        {
                                            audiolist && audiolist.hasOwnProperty('id') && <RoundAudio item={audiolist} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="save-share-mix-icon-wrapper flex-shrink-0 mb-5 pb-5">
                                <div className="d-flex align-items-center justify-content-center mb-3 d-sm-none">
                                    <div className="whatsapp-icon position-relative mx-1">
                                        <WhatsappShareButton
                                            url={shareUrl}
                                            beforeOnClick={() => beforeClick()}
                                            quote={'Title or jo bhi aapko likhna ho'}
                                            hashtag={'#portfolio...'}
                                        >
                                            <WhatsappIcon size={50} round={true} />
                                        </WhatsappShareButton>
                                        <button
                                            className="notButton "
                                        >
                                            <img src={SaveShareMixWhatsappIcon} alt="Close Button" style={{ width: '50px' }} />
                                        </button>
                                    </div>
                                    <div className="messenger-icon position-relative mx-1 d-none">
                                        <FacebookMessengerShareButton
                                            url={shareUrl}
                                            quote={'Title or jo bhi aapko likhna ho'}
                                            hashtag={'#portfolio...'}
                                            beforeOnClick={() => beforeClick()}
                                        >
                                            <FacebookMessengerIcon size={50} round={true} />
                                        </FacebookMessengerShareButton>
                                        <button
                                            className="notButton"
                                        >
                                            <img src={SaveShareMixMessenerIcon} alt="Close Button" style={{ width: '50px' }} />
                                        </button>
                                    </div>
                                    <div className="copy-link-icon position-relative mx-1">
                                        <button
                                            className="notButton "
                                            onClick={copyURL}
                                        >
                                            <img src={SaveShareMixLinkIcon} alt="Close Button" style={{ width: '50px' }} />
                                        </button>
                                    </div>
                                </div>
                               
                                <div className="d-flex align-items-center justify-content-center">
                                {/* <div className="whatsapp-icon position-relative mx-1">
                                    <WhatsappShareButton
                                        url={shareUrl}
                                        quote={'Title or jo bhi aapko likhna ho'}
                                        hashtag={'#portfolio...'}
                                        beforeOnClick={() => { }}
                                    >
                                        <WhatsappIcon size={50} round={true} />
                                    </WhatsappShareButton>
                                    <button
                                        className="notButton"
                                    >
                                        <img src={SaveShareMixWhatsappIcon} alt="Close Button" style={{ width: '50px' }} />
                                    </button>
                                </div> */}
                                    <div className="message-icon position-relative mx-1 d-none">
                                        {/* <button
                                            className="notButton "
                                        >
                                            <img src={SaveShareMixIphoneMessageIcon} alt="Close Button" style={{ width: '50px' }} />
                                        </button> */}
                                    </div>
                                    <div className="email-icon position-relative mx-1">
                                        {/* <EmailShareButton
                                            url={shareUrl}
                                            quote={'Title or jo bhi aapko likhna ho'}
                                            beforeOnClick={() => beforeClick()}
                                        >
                                            <EmailIcon size={50} round={true} />
                                        </EmailShareButton> */}
                                        {/* <button
                                            className="notButton "
                                            onClick={() => { setHouseHoldEmailModal(true); }}
                                        >
                                            <img src={SaveShareMixEmailIcon} alt="Close Button" style={{ width: '50px' }} />
                                        </button> */}
                                    </div>
                                    <div className="copy-link-icon position-relative mx-1 d-none d-md-block">
                                        <button
                                            className="notButton "
                                            onClick={copyURL}
                                        >
                                            <img src={SaveShareMixLinkIcon} alt="Close Button" style={{ width: '50px' }} />
                                        </button>
                                    </div>
                                   
                                    {/* <div className="more-icon position-relative mx-1 d-sm-none">
                                        <button
                                            className="notButton "
                                            onClick={closeAllPanels}
                                        >
                                            <img src={SaveShareMixMoreIcon} alt="Close Button" style={{ width: '50px' }} />
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* copied modal */}
            <Modal
                show={copiedText}
                centered
                className="copied-modal"
                style={{marginTop:'-10px'}}
                backdrop="static"
            >
                <Modal.Body>
                    <div className="p-5">
                        <h2 className="font-16 lh-16 m-0 fw-normal text-center py-5 my-5">Link Copied!</h2>
                    </div>
                </Modal.Body>
            </Modal>


            {/* copied modal */}
            <Modal
                show={houseHoldEmailModal}
                centered
                className="soundModal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="text-end">
                        <button
                            className="closeButton notButton ms-auto"
                            onClick={() => { setHouseHoldEmailModal(false); }}
                        >
                            {/* <CrossIcon /> */}
                            <img src={crossIcon} alt="" />
                        </button>
                    </div>
                    <div className="customModalContent mt-4">
                        <h2 className="font-16 lh-16 fw-normal mb-3 ps-3">Email</h2>
                        <div>
                            <input type="email" required ref={emailRef} className="searchBar w-100" />
                            {houseHoldEmailMsg.error && <small className="text-danger">{houseHoldEmailMsg.text}</small>}
                        </div>
                        <div className="mt-4 d-flex gap-3 justify-content-center mb-4">
                            <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={houseHoldShare}>
                                <span className=''>Send</span>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={houseHoldSuccesModal}
                centered
                className="invite-success-modal success-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="p-5 px-3">
                        <h2 className="font-16 lh-16 my-5 fw-normal text-center">Email Sent.</h2>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
};

export default ShareMixSave;
