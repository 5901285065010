import React, { useEffect } from 'react'
import { isIOS, isMobileSafari, isSafari } from 'react-device-detect'

const Redirection = () => {

    useEffect(() => {
        if (isMobileSafari || isSafari || isIOS) {
            window.location.href = "https://apps.apple.com/us/app/relax-scofa/id6476312399"
        } else {
            window.location.href = "https://play.google.com/store/apps/details?id=com.scofarelax&hl=en_US"
        }
        setTimeout(() => {
            window.close();
        }, 500)
    }, [])


    return (
        <section className="mainWrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="mainCategories justify-content-center">
                            <h2>Redirecting to {isMobileSafari || isSafari || isIOS ? 'App Store' : 'Play Store'}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Redirection