import { useEffect, useState } from "react";
import axios from "axios";
import RoundAudio from "../../Components/RoundAudio";
import Accordion from "react-bootstrap/Accordion";
import BASEURL from "../../Config/global";
import "./style.css";
import { useDispatch } from "react-redux";
import { userLogout } from "../../Store/Slices/APIs";

const SoundsAccordian = ({ currentSounds, handleClick, soundlist, clickListner, listner, list }) => {

  const dispatch = useDispatch();
  const [categorizedSoundsData, setCategorizedSoundsData] = useState([]);

  useEffect(() => {
    dispatch(userLogout())
  }, [])

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${BASEURL}/api/sounds/allsoundscategories`);
        if (response.data.error != true) {
          const sortedData = response.data.data.sort(function (a, b) { return a.sequence - b.sequence })
          setCategorizedSoundsData(sortedData);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);



  return (
    <div className="row g-0">
      <div className="col-12">
        <Accordion
          defaultActiveKey={["0"]}
          alwaysOpen
          className="customAccordion sound-accordian"
        >
          {categorizedSoundsData && categorizedSoundsData?.map((categorizedData, index) => {
            let sortedChildrens = categorizedData.children_category_data
            if (sortedChildrens?.length > 0) {
              sortedChildrens = sortedChildrens.sort(function (a, b) { return a.sequence - b.sequence })
            }
            return (
              (
                <Accordion.Item eventKey={index == 0 ? '0' : Math.random()} key={Math.random()}>
                  <Accordion.Header
                    style={{
                      backgroundImage: `url(${categorizedData?.banner})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundColor: '#1c3664',
                    }}
                  >
                    {categorizedData?.name}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="soundBoxWrapper">
                      {categorizedData?.category_data?.length > 0 && categorizedData?.category_data?.map((item, idx) =>
                      (
                        <RoundAudio key={idx} item={item} clicklistner={listner} showThumnail={true} />
                      ))}
                    </div>
                    {sortedChildrens?.length > 0 && sortedChildrens?.map((categorizedDataiii, index2) => (
                      <Accordion.Item eventKey={Math.random()} key={Math.random()}>
                        <Accordion.Header
                          className="sub-category-header"
                          style={{
                            backgroundImage: `url(${categorizedDataiii?.banner})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundColor: '#1c3664',
                          }}
                        >
                          {categorizedDataiii?.name}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="soundBoxWrapper">
                            {categorizedDataiii?.category_data?.length > 0 && categorizedDataiii?.category_data?.map((item, idx) => (
                              <RoundAudio key={idx} item={item} clicklistner={listner} showThumnail={true} />
                            ))}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              )
            )
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default SoundsAccordian;
