import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MainLayout from '../../Layout/MainLayout'
import { Link } from 'react-router-dom'
import { BackButton, BackButtonNew } from '../../Assets/svg'
import BASEURL from '../../Config/global'
import './style.css'
const TermsConditions = () => {
    const [policy, setPolicy] = useState()
    useEffect(() => {
        fetchTermsConditions()
    }, [])
    const fetchTermsConditions = async () => {
        try {
            const obj = {
                portal: "web"
            }
            const URL = `${BASEURL}/api/misc/terms-and-conditions?portal=web`
            const response = await axios.get(URL,obj)
            if (response?.status === 200) {
                setPolicy(response?.data?.data)
            }
        } catch (err) {
            console.log("err", err)
        }
    }
    return (
        <>
            <MainLayout>
                <div className="go-back pb-4 d-inline-block">
                    <Link to={`/more-options`} className='rotate d-flex align-items-center text-decoration-none'>
                        <img src={BackButtonNew} className="mw-100 w-22" />
                        <h6 className='mb-0'>Back</h6>
                    </Link>
                </div>

                <div className='terms-global-style' dangerouslySetInnerHTML={{ __html: policy }} />
            </MainLayout>
        </>
    )
}

export default TermsConditions