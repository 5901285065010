import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { favicon } from "../../../Assets/images";
import { appDownload, crossIcon } from "../../../Assets/svg";
import { isIOS, isMobileSafari, isSafari } from "react-device-detect";
import './style.css'
import BASEURLFrontend from "../../../Config/urls";

function QrCodeGenerator({ qrIsVisible, handleQrCode }) {

    const [url, setUrl] = useState(`${BASEURLFrontend}/qr-code`);

    const handleQrCodeGenerator = () => {
        if (!url) {
            return;
        }
        handleQrCode(!qrIsVisible);
    };

    return (
        <div className="qrcode__container">
            <button className="qrcode__button" onClick={handleQrCodeGenerator}>
                <img src={appDownload} style={{ width: '20px' }} />
                <span>Get the App</span>
            </button>
            <div className={`${qrIsVisible ? 'qrcode_popup__open' : 'qrcode_popup'}`} >
                <div className={`${qrIsVisible ? 'qrcode__download__1n349' : 'qrcode__download'}`}>
                    <div className="header position-relative w-100 d-flex align-items-center justify-content-center" style={{ height: '72px' }}>
                        <button className="closeButton notButton ms-auto d-flex p-3" onClick={() => { handleQrCode(false) }}>
                            <img src={crossIcon} alt="" />
                        </button>
                        <h6 className="mb-0">Download App</h6>
                    </div>
                    <p className="text-center fw-light small mt-3 mb-0">Scan QR code with your Mobile or Tablet/iPad to download the App</p>
                    <div className="p-3 pb-4 d-flex flex-column mb-2">
                        {/* <p className='fw-bold text-center' style={{ fontSize: '16px !important', color: '#EFB729' }}  >IOS App Available Now</p> */}
                        <div className="d-flex mx-auto qrcode__image bg-white p-2">
                            <QRCode value={url} size={150} />
                        </div>
                        {/* <hr /> */}
                        {/* <p className='fw-bold text-center' style={{ fontSize: '16px !important', color: '#EFB729' }}  >Android App Coming Soon</p> */}
                    </div>
                </div>
            </div>
        </div >
    );
}
export default QrCodeGenerator;