import React, { useEffect, useState } from 'react'
import MainLayout from '../../Layout/MainLayout'
import axios from 'axios';
import BASEURL from '../../Config/global';
import { Link } from 'react-router-dom';
import "./style.css";

const UpgradeSubscription = () => {

    const [membership, setMembership] = useState([]);
    const user = JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
        getMembership();
    }, [])

    const getMembership = async () => {
        try {
            const response = await axios.get(
                `${BASEURL}/api/user/packages`
            );
            if (!response?.data?.error) {
                setMembership(response.data.data);
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <MainLayout>
            <div className="row pt-4 pb-lg-5 mb-4">
                <div className="col-12 text-center">
                    {/* <h2 className="fs-3 fw-bold mb-3">
                        Upgrade Your
                    </h2> */}
                    <h3 className="fs-4">
                        Membership Plan
                    </h3>
                </div>
            </div>
            <div className="row justify-content-center">
                {
                    membership.length > 0 && membership.map((item, index) => (
                        <div className={(!(user?.package_detail?.[0]?.type === 'coupons') && item?.interval === user?.package_detail?.[0]?.['interval']) ? 'col-md-4 mb-3 card-disabled d-none' : 'col-md-4 mb-3'} key={index}>
                            <div className="memberShipCard">
                                <p className='goldenColor mb-2'>{item?.title}</p>
                                <h3 className='mb-2'>{(item?.price) ? `$ ${item?.price}` : ''}</h3>
                                <Link className="customButton primaryButton mt-3" to={`/upgrade-subscription/${item?.id}`}>{item.id === user.package ? 'Current Plan' : 'Change Plan'}</Link>
                            </div>
                        </div>
                    ))
                }
            </div>
        </MainLayout>
    )
}

export default UpgradeSubscription