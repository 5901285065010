import { configureStore } from "@reduxjs/toolkit";
import UserSlice from "./Slices/UserSlice";
import AudioSlice from "./Slices/AudioSlice";
import SoundPlayerSlice from "./Slices/SoundPlayerSlice";
import MixerSlice from "./Slices/MixerSlice";
import MyMixerSlice from "./Slices/MyMixerSlice";
import ExternalMixerSlice from "./Slices/ExternalMixerSlice";
import APIs from "./Slices/APIs";

const store = configureStore({
    reducer: {
        user: UserSlice,
        audio: AudioSlice,
        soundPlayer: SoundPlayerSlice,
        mixer: MixerSlice,
        mymixer: MyMixerSlice,
        externalmixerslice: ExternalMixerSlice,
        APIS: APIs,
    }
})

export default store