import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MainLayout from "../../Layout/MainLayout";
import { BackButton, BackButtonNew, crossIcon } from "../../Assets/svg";
import axios from "axios";
import BASEURL from "../../Config/global";
import { Modal } from "react-bootstrap";
import { setUserDetails } from "../../Store/Slices/UserSlice";
import { useDispatch } from "react-redux";
import { removeAccessToken } from "../../Util/authHeader";

const MoreOptions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
  const [succesModal, setSuccesModal] = useState(false);
  const [platformMessageModal, setPlatformMessageModal] = useState(false);

  const cancelSubscription = async () => {
    try {
      const currentUser = localStorage.getItem("user");
      const parsedUser = JSON.parse(currentUser);
      const response = await axios.delete(`${BASEURL}/api/user/cancel-user-subscription/${parsedUser.id}/`);
      if (!response.data.error) {
        setSuccesModal(true);
        setTimeout(() => {
          setSuccesModal(false);
          handleLogout();
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleLogout = () => {
    removeAccessToken();
    localStorage.removeItem("user");
    localStorage.removeItem("is_primary");
    localStorage.removeItem("user_email");
    localStorage.removeItem("ip");
    localStorage.removeItem("userId");
    dispatch(setUserDetails(null));
    setTimeout(() => {
      navigate("/login");
      window.location.reload(false)
    }, 500);
  };

  const checkCancelSubscription = () => {
    const currentUser = localStorage.getItem("user");
    const parsedUser = JSON.parse(currentUser);
    if (parsedUser.platform === 'mobile') {
      setPlatformMessageModal(true);
    } else {
      setCancelSubscriptionModal(true);
    }
  }


  return (
    <>
      <MainLayout>
        <div className="row g-0 g-lg-">
          <div className="col-12 px-0 px-lg">
            <div className="go-back pb-4 ps-2 d-inline-block rotate">
              <Link to={`/settings`} className='d-flex align-items-center text-decoration-none'>
                <img src={BackButtonNew} className="mw-100 w-22" />
                <h6 className='mb-0'>Back</h6>
              </Link>
            </div>
            <div className="linkWrapper">
              <Link to={"https://relaxscofa.com/faq/"} className="text-decoration-none fw-light">
                FAQ
              </Link>
            </div>
            <div className="linkWrapper">
              <Link to={"/privacy-policy"} className="text-decoration-none fw-light">
                Privacy Policy
              </Link>
            </div>
            <div className="linkWrapper">
              <Link to={"/terms-of-service"} className="text-decoration-none fw-light">
                Terms of Service
              </Link>
            </div>
            <div className="linkWrapper fw-light">
              For help, please contact
              <Link to={"https://relaxscofa.com/support/"} className="fw-medium ps-1 ">
                support
              </Link>
            </div>
            <div className="linkWrapper">
              <button onClick={checkCancelSubscription} className="text-decoration-none fw-light btn text-white p-0">
                Cancel Subscription
              </button>
            </div>
          </div>
        </div>
      </MainLayout>

      <Modal
        show={cancelSubscriptionModal}
        centered
        className="soundModal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="text-end">
            <button
              className="closeButton notButton ms-auto"
              onClick={() => setCancelSubscriptionModal(false)}
            >
              <img src={crossIcon} alt="" />
            </button>
          </div>
          <div className="customModalContent my-5">
            <h2 className="font-16 lh-16 fw-normal mb-5 text-center">Are you sure you want to cancel the Subscription?</h2>
            <div className="mt-4 d-flex align-items-center justify-content-center">
              <button style={{ backgroundColor: '#ffffff1f' }} className='mx-3 px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={cancelSubscription}>
                <span className=''>Yes</span>
              </button>
              <button style={{ backgroundColor: '#ffffff1f' }} className='mx-3 px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={() => setCancelSubscriptionModal(false)}>
                <span className=''>No</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      {/* success modal */}
      <Modal
        show={succesModal}
        centered
        className="success-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="p-5">
            <h2 className="font-16 lh-16 my-5 fw-normal text-center">Subscription Cancelled</h2>
          </div>
        </Modal.Body>
      </Modal>

      {/* success modal */}
      <Modal
        show={platformMessageModal}
        centered
        className="soundModal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="text-end">
            <button
              className="closeButton notButton ms-auto"
              onClick={() => setPlatformMessageModal(false)}
            >
              <img src={crossIcon} alt="" />
            </button>
          </div>
          <div className="p-5">
            <h2 className="font-16 lh-16 my-5 fw-normal text-center">To edit your payment details, Please use your Relax Scofa Mobile App.</h2>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default MoreOptions;
