/**
    * @description      : 
    * @author           : Saif
    * @group            : 
    * @created          : 07/11/2023 - 00:33:55
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 07/11/2023
    * - Author          : Saif
    * - Modification    : 
**/
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { playAudio } from "../../Store/Slices/SoundPlayerSlice";

import axios from "axios";

import BASEURL from "../../Config/global";
import MainLayout from "../../Layout/MainLayout";

import { BackButton, BackButtonNew, OutlinePause, OutlinePlay, PauseButton, PlayButton } from "../../Assets/svg";
import "./style.css";
import { pauseMixer, playMixer } from "../../Store/Slices/MixerSlice";
import VolumeBar from "../../Components/VolumeBar";
import ReactAudioPlayer from "react-audio-player";
import { AudioDurationClose, AudioDurationCurrentTime, AudioDurationStop, AudioSeek } from "../../Store/Slices/AudioSlice";
import { Link } from "react-router-dom";
import AudioStrip from "../../Components/AudioStrip";
import { isIOS, isSafari } from "react-device-detect";
import { Howl, Howler } from "howler";
import { userLogout } from "../../Store/Slices/APIs";
// import { FillPlay, FillPause, TrackedPlay } from '../Assets/svg';
import CryptoJS from 'crypto-js';

const AudioDetail = () => {
  const { type, id:paramId } = useParams();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const encrypted = params.get('encrypted');

  // Get Data From Redux
  const audio = useSelector((state) => state.soundPlayer.audio);
  const isPlaying = useSelector((state) => state.mixer.play);
  const AudioHowl = useSelector((state) => state.audio.howlAudioPlay);
  const AudioStopTime = useSelector((state) => state.audio.audioStopTime);
  const AudioDurationCurrentTimer = useSelector((state) => state.audio.audioDurationCurrentTime);

  const [data, setData] = useState({});
  const [moreStories, setMoreStories] = useState([]);
  const [isActiveAudio, setIsActiveAudio] = useState(false);
  const [isPlayingState, setIsPlayingState] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(true);
  // States For Player
  const [audioTimer, setAudioTimer] = useState(null);
  const [initialAudioTimer, setInitialAudioTimer] = useState('00:00');
  const [totalAudioTimer, setTotalAudioTimer] = useState('00:00');
  const [totalDuration, setTotalDuration] = useState(0);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [globalSeekTime, setGlobalSeekTime] = useState(1);
  // More Stories
  const [apiUrl, setApiUrl] = useState('');
  const [id, setId] = useState();


  useEffect(() => {
    dispatch(userLogout())
  }, [])
  useEffect(() => {
    if (encrypted && encrypted === 'yes') {
      decryptText(paramId)
    } else {
      setId(paramId)
    }
  }, [paramId])
  
  const decryptText = (no) => {
    const key = 'secret-key'; // Replace with your secret key
    const idTodecrypt = no.toString()
    const decryptedId = idTodecrypt?.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');
    const decryptedText = CryptoJS.AES.decrypt(decryptedId, key);
    const decrypted = decryptedText?.toString(CryptoJS.enc.Utf8);
    setId(decrypted);
  };

  useEffect(() => {
    if(!id){
      return
    }
    if (type === "relaxation") {
      setApiUrl(`${BASEURL}/api/meditation/`)
      fetchRelaxation();
    } else if (type === "stories") {
      setApiUrl(`${BASEURL}/api/stories/`)
      fetchStory();
    } else if (type === "articles") {
      setApiUrl(`${BASEURL}/api/articles/`)
      fetchArticle();
    }else if( type === "sounds"){
      setApiUrl(`${BASEURL}/api/sounds/`)
      fetchSound()
    }
  }, [type, id]);

  useEffect(() => {
    async function MoreStoriesData() {
      try {
        const response = await axios.get(apiUrl);
        if (!response.data.error) {
          setMoreStories(response.data.data);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (apiUrl != '') {
      MoreStoriesData();
    }
  }, [apiUrl])

  useEffect(() => {
    if (audio && audio.audio == data.audio) {
      setIsActiveAudio(true);
    }
    else {
      setIsActiveAudio(false);
    }
  }, [id, audio, data]);

  const fetchRelaxation = async () => {
    try {
      const response = await axios.get(`${BASEURL}/api/meditation/${id}`);
      if (response.data.error != true) {
        setData(response.data.data);
        setIsLoading(false);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStory = async () => {
    try {
      const response = await axios.get(`${BASEURL}/api/stories/${id}`);
      if (response.data.error != true) {
        setData(response.data.data[0]);
        setIsLoading(false);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchArticle = async () => {
    try {
      const response = await axios.get(`${BASEURL}/api/articles/${id}`);
      if (response.data.error != true) {
        setData(response.data.data[0]);
        setIsLoading(false);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchSound = async () => {
    try {
      const response = await axios.get(`${BASEURL}/api/sounds/${id}`);
      if (response.data.error != true) {
        setData(response.data.data[0]);
        setIsLoading(false);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };


  // === 11/04/2023 === //
  useEffect(() => {
    if (data.hasOwnProperty('audio')) {
      const howl = new Howl({
        src: [data.audio],
        loop: false,
        autoplay: false,
        webAudio: true, // Use Web Audio API if supported
        html5: true, // Use HTML5 audio if supported
        autoUnlock: true,
        preload: true,
        volume: 0.5,
        autoSuspend: false,
        onload: function () {
          this.loaded = true;
          console.log(howl._duration, "0000");
          SetDurations(howl._duration);
        },
      });
    }
  }, [data]);
  // === 11/04/2023 === //

  const SetDurations = (seektime) => {
    let minutes = Math.floor(seektime / 60);
    let seconds = seektime - minutes * 60;
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    setTotalAudioTimer(`${minutes}:${seconds.toString().slice(0, 2)}`);
  }

  useEffect(() => {
    if (data) {
      if (audio.audio == data.audio) {
        setIsActiveAudio(true)
      }
      else {
        setIsActiveAudio(false)
      }
    }
  }, [audio, data])

  useEffect(() => {
    if (data) {
      if (audio.audio == data.audio && isPlaying) {
        setIsPlayingState(true)
      }
      else {
        setIsPlayingState(false)
      }
    }
  }, [audio, data])

  useEffect(() => {
    if (isPlaying) {
      setIsPlayingState(true);
      setAudioTimer(AudioStopTime);
    }
    else {
      setIsPlayingState(false);
      dispatch(AudioDurationStop(audioTimer));
      setAudioTimer(null);
    }
  }, [isPlaying])

  useEffect(() => {
    if (AudioHowl) {
      setAudioTimer(globalSeekTime);
    }
  }, [AudioHowl])

  useEffect(() => {
    if (Number(audioTimer) >= Number(totalDuration - 1)) {
      setAudioTimer(null);
    }
    if (!audioTimer) return;
    const intervalId = setInterval(() => {
      setAudioTimer(audioTimer + 1);
      sortMinutesSeconds(audioTimer + 1);
      dispatch(AudioDurationCurrentTime(audioTimer));
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [audioTimer])


  let appendReactPlayer = [];

  appendReactPlayer.push(
    <div>
      <ReactAudioPlayer
        src={data?.audio}
        controls={true}
        autoPlay={false}
        loop={false}
        onCanPlay={e => isReadyToPlay(e)}
      />
    </div>
  )

  const isReadyToPlay = (e) => {
    setTotalDuration(e.target.duration);
    let minutes = Math.floor(e.target.duration / 60);
    let seconds = e.target.duration - minutes * 60;
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    setTotalAudioTimer(`${minutes}:${seconds.toString().slice(0, 2)}`);
  }

  const sortMinutesSeconds = (time) => {
    setCurrentDuration(time);
    let minutes = Math.floor(time / 60);
    let seconds = time - minutes * 60;
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    setInitialAudioTimer(`${minutes}:${seconds.toString().slice(0, 2)}`);
  }

  const seekControl = (seektime) => {
    dispatch(AudioSeek(Number(seektime)));
    setGlobalSeekTime(Number(seektime));
    if (isActiveAudio && isPlaying) {
      if (Number(seektime) >= 1) {
        setAudioTimer(Number(seektime));
      } else {
        setAudioTimer(Number(1));
      }
    }
    setCurrentDuration(seektime);
    let minutes = Math.floor(seektime / 60);
    let seconds = seektime - minutes * 60;
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    setInitialAudioTimer(`${minutes}:${seconds.toString().slice(0, 2)}`);
  }

  const handlePlay = () => {
    if (isActiveAudio) {
      if (isPlaying) {
        dispatch(pauseMixer());
        setIsPlayingState(false);
      } else {
        dispatch(playMixer());
        setIsPlayingState(true);
      }
    } else {
      dispatch(playAudio(data));
      dispatch(playMixer());
      setIsPlayingState(true);
    }
  };

  let duration = data?.duration ? data?.duration % 60 : '';
  // {Math.floor(data.duration / 60) + '-----' + Math.round(data?.duration * 100) / 100}

  const secondConvertToMints = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    let extraSeconds = Math.trunc(seconds % 60);
    let result = minutes + ':' + extraSeconds.toString().slice(0, 2)
    return result;
  }



  return (
    <MainLayout>
      {/* <div className="faded-image">
        <div className="overlay"></div>
        <img
          src={data.image}
          alt=""
          onLoad={() => {
            setIsImageLoading(false);
          }}
        />
      </div> */}

      <div className="go-back rotate">
        <Link to={`/${type}`}><img src={BackButtonNew} className="mw-100 w-22" /> </Link>
      </div>

      <div className="audio-detail-page mt-3 mt-sm-4">
        <div className="row">
          <div className="col-lg-12 m-auto">
            {
              !isLoading && (
                <div className="box-wrapper">
                  <div className={`image-thumbnail ${isImageLoading ? "d-none" : ""}`}>
                    <img
                      src={data.image}
                      className="img-fluid w-100 rounded-4"
                      onLoad={() => setIsImageLoading(false)}
                    />
                  </div>
                  <div className="content text-center px-3">
                    <h6 className="text-white text-capitalize mt-4 pt-2 d-none d-sm-block" >{data.type}</h6>
                    <h3 className="text-white my-3 my-sm-4 px-4">{data.title}</h3>
                    <h5 className="text-white mb-2 mb-sm-4">Duration: {secondConvertToMints(data.duration)}  Min</h5>
                    {/* <p className="text-white mb-3 d-none d-sm-block" style={{ lineHeight: '1.7' }}>img elements must have an alt prop, either with meaningful text, or an empty string for decorative images  </p> */}
                    <h5 className="text-white mb-2 d-sm-none text-capitalize">{data.type}</h5>
                    <div className="ppButton mt-3 mt-sm-0 pt-sm-2">
                      <button
                        type="button"
                        className="no-button"
                        onClick={handlePlay}
                      >
                        {isPlayingState && isActiveAudio ? (
                          <img src={PauseButton} />
                        ) : (
                          <img src={PlayButton} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>

      {/* <div className="row mb-4 position-relative">
        <div className="col-md-6 m-auto">
          <div className="row">
            <div className="col-lg-12 my-2 m-auto">
              {/* {isLoading && isImageLoading && (
                <div className="customCardSkeleton skeleton"></div>
              )} */}
              {/* <div className={`audioImage mt-3 ${isImageLoading ? "d-none" : ""}`}>
                <img
                  src={ data.image}
                  alt=""
                  onLoad={() => {
                    setIsImageLoading(false);
                  }}
                />
              </div> 
              <div className="audioData">
                <div className={`audioDetails ${isLoading ? "d-none" : ""} text-center mt-4`}>
                  <h2 className="audioTitle mb-0 mt-2">{data.title}</h2>
                  <h4 className="audioCategory my-3">{data.meditationcategoriesname}</h4>
                  <h5 className="audioType">Duration: {Math.floor(data.duration / 60)} mins</h5>
                </div>
              </div>
              <div className="controlOption mt-1">
                <div className="audioAction">
                  <div className="ppButton">
                    <button
                      type="button"
                      className="audioButton"
                      onClick={handlePlay}
                      style={{ width: '150px' }}
                    >
                      {isPlayingState && isActiveAudio ? (
                        <img src={OutlinePause} />
                      ) : (
                        <img src={OutlinePlay} />
                      )}
                    </button>
                  </div>
                  <div className="volu col-md-12 d-none">
                    <div className="d-none">
                      {appendReactPlayer}
                    </div>
                    <div className="d-flex align-items-center single-audio-playerbar py-3">
                      <p className="mb-0 text-white">{initialAudioTimer}</p>
                      <div className="playerVolume mx-3 mb-2 flex-grow-1">
                        <input
                          type="range"
                          min={0}
                          max={totalDuration}
                          value={currentDuration}
                          onChange={(e) => { seekControl(e.target.value) }}
                        />
                      </div>
                      <p className="mb-0 text-white">{totalAudioTimer}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div> */}
      {/* <div className="row mt-5">
        <div className="col-md-6 m-auto">
          <div className="more-stories">
            {data.type && <h3 className="fs-5 text-white mb-4" style={{ fontWeight: 700 }}>More <span className="text-capitalize">{data.type}</span> like this</h3>}
            <div className="more-storeis-lists">
              {
                moreStories.length > 0 && moreStories.map((item, index) => {

                  return index < 3 && (
                    <div className="audioStrip ps-md-0">
                      <Link
                        to={`/audio-detail/${item.type}/${item.id}`}
                        className="audioStripDetails">
                        <img src={`${ item?.thumbnail}`} alt="" />
                        <p>{item?.title}</p>
                      </Link>
                      <div className="audioStripExtra">
                        <p className="audioStripduration">{item?.tag && item?.tag}</p>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div> */}
    </MainLayout>
  );
};

export default AudioDetail;