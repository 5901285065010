import "./style.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeSound, playSound, playAudio, removeAudio, removeMusic, playMusic } from "../../Store/Slices/SoundPlayerSlice";
import { activeUser } from "../../Store/Slices/APIs";

const CustomCard = (props) => {
  const sounds = useSelector((state) => state.soundPlayer.sounds);
  const music = useSelector((state) => state.soundPlayer.music);
  const audio = useSelector((state) => state.soundPlayer.audio);
  const [isLoading, setIsLoading] = useState(true);
  const [addClass, setAddClass] = useState(false);
  const [addMusicClass, setAddMusicClass] = useState(false);
  const dispatch = useDispatch();


  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const dispatchRemoveSound = (item) => {
    dispatch(removeSound(item))
  }

  const dispatchPlaySound = (item) => {
    if (item.soundcategory === 164) {
      if (addMusicClass) {
        dispatch(removeMusic());
        setAddMusicClass(false)
      } else {
        dispatch(activeUser());
        dispatch(playMusic(item));
      }
    } else {
      dispatch(activeUser());
      dispatch(playSound(item));
    }
    // dispatch(playSound(item))
  }

  const dispatchPlayAudio = (item) => {
    dispatch(activeUser());
    dispatch(playAudio(item))
  }

  const dispatchRemoveAudio = (item) => {
    dispatch(removeAudio(item))
  }

  // console.log(sounds, audio, music)
  // console.log(props.item)
  useEffect(() => {

    if (sounds) {
      const soundExists = sounds.some((sound) => sound?.id === props?.item?.id);
      setAddClass(soundExists)
      // console.log(sounds)
    }

    if (audio) {
      // console.log(audio)
      if (audio?.id == props?.item?.id) {
        setAddClass(true)
      }
    }

    // if (music.length) {
    //   const soundExists = music.some((mus) => mus?.id === props?.item?.id);
    //   setAddClass(soundExists)
    //   // console.log(music)
    // }
  }, [sounds, music, audio])

  useEffect(() => {
    if (music?.id == props?.item?.id) {
      setAddMusicClass(true)
    } else {
      setAddMusicClass(false)
    }
  }, [music])

  return (
    <>
      {/* {isLoading && <div className="customCardSkeleton skeleton"></div>} */}
      <div
        className={`customCard ${(addClass || addMusicClass) ? 'grendientBox' : ''}`}
        onClick={() => {
          if (props?.item?.type == 'sounds') {
            addClass ? dispatchRemoveSound(props?.item) : dispatchPlaySound(props?.item)
          } else {
            addClass ? dispatchRemoveAudio(props?.item) : dispatchPlayAudio(props?.item)
          }
        }}
      // onClick={props.onClick}
      >
        <img
          src={props?.image}
          alt={props?.alt}
          className="customCardImage"
        />
        <div className="customCardOverlay"></div>
        <h3 className="customCardTitle">{props?.title}</h3>
      </div>
    </>
  );
};

export default CustomCard;
