import { Link } from "react-router-dom";
import { androidAppSnap, androidMobileTablet, androidQRCode, appStoreHD, desktop, googlePlayHD, iosAppSnap, iosIpad, iosQRCode, metaVR3, smartTvFrame, Tick } from "../../Assets/svg";
import './style.css'
import QrCodeGenerator from "../../Components/MenuBar/QR-code";
import QRCode from "react-qr-code";
import BASEURLFrontend from "../../Config/urls";
import { useState } from "react";

const DownloadApp = () => {
  const [url, setUrl] = useState(`${BASEURLFrontend}/qr-code`);

  return (
    <section className="fullPageBg">
      <div className="container">
        <div className="row ">
          <div className="col-12 col-lg-10 mx-auto">
            <div className={`fullPage  text-margin-zero`}>
              <p className="first-heading mt-2">Thank you for <br className="d-md-none" />joining Relax Scofa!</p>
              <p className="second-heading">Download the app</p>
              <p className="text-center third-heading d-none d-md-block">
                Get the App for your Mobile or Tablet device. Simply scan the QR Code with your Phone,<br className="" />
                Tablet or iPad's camera and follow the link to the App Store and download the App.
              </p>
              <div className=" flex-row justify-content-evenly w-100 d-none d-md-flex gap-0">
                <div className="d-flex flex-row align-items-start gap-4">
                  <img src={androidAppSnap} style={{ width: '59px' }} />
                  <div className="d-flex flex-column gap-4">
                    <QRCode value={'https://play.google.com/store/apps/details?id=com.scofarelax&hl=en_US'} size={120} />
                    {/* <img src={androidQRCode} style={{ width: '120px' }} /> */}
                    <Link to={"https://play.google.com/store/apps/details?id=com.scofarelax&hl=en_US"} className="appLink">
                      <img src={googlePlayHD} alt="Google Play" />
                    </Link>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-start gap-4">
                  <img src={iosAppSnap} style={{ width: '59px' }} />
                  <div className="d-flex flex-column gap-4">
                    <QRCode value={'https://apps.apple.com/us/app/relax-scofa/id6476312399'} size={120} />
                    {/* <img src={iosQRCode} style={{ width: '120px' }} /> */}
                    <Link to={"https://apps.apple.com/us/app/relax-scofa/id6476312399"} className="appLink">
                      <img src={appStoreHD} alt="App Store" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="d-flex d-md-none flex-column w-100 align-items-center gap-4">

                <Link to={"https://apps.apple.com/us/app/relax-scofa/id6476312399"} className="appLink">
                  <img src={appStoreHD} alt="App Store" className="w-100" />
                </Link>
                <Link to={"https://play.google.com/store/apps/details?id=com.scofarelax&hl=en_US"} className="appLink">
                  <img src={googlePlayHD} alt="Google Play" className="w-100" />
                </Link>

              </div>
              <p className="text-center fourth-heading">
                Use Relax Scofa on:
              </p>
              <div className="d-md-none">
                <div className="d-flex flex-row justify-content-around mobile-svg mb-4 mx-5">
                  <ImageWithCaption icon={iosIpad} caption={<span className="text-center">iOS iPhone <br /> and iPad</span>} />
                  <ImageWithCaption icon={androidMobileTablet} caption={<span className="text-center">Android Mobile <br />and Tablet</span>} />
                </div>
                <div className="d-flex flex-row justify-content-evenly mobile-svg  ">
                  <ImageWithCaption icon={desktop} caption={<span className="text-center">Desktop</span>} />
                  <ImageWithCaption icon={smartTvFrame} caption={<span className="text-center">Smart TV With <br />Web Browsers</span>} />
                  <ImageWithCaption icon={metaVR3} caption={<span className="text-center">Meta 3 VR Through <br />Web Browser</span>} />

                </div>
              </div>
              <div className="row p-0 m-0 w-100">
                <div className="col-lg-12 col-xl-9 mx-auto">
                  <div className="d-md-flex d-none justify-content-between mobile-svg w-100 ">
                    <ImageWithCaption icon={iosIpad} caption={<span className="text-center">iOS iPhone <br /> and iPad</span>} />
                    <ImageWithCaption icon={androidMobileTablet} caption={<span className="text-center">Android Mobile <br />and Tablet</span>} />
                    <ImageWithCaption icon={desktop} caption={<span className="text-center">Desktop</span>} />
                    <ImageWithCaption icon={smartTvFrame} caption={<span className="text-center">Smart TV With<br /> Web Browsers</span>} />
                    <ImageWithCaption icon={metaVR3} caption={<span className="text-center">Meta 3 VR Through<br /> Web Browser</span>} />
                  </div>
                  <div className="d-flex justify-content-center align-items-center w-100 flex-column d-md-none">
                    <ListItem icon={Tick} data={["Sounds & Music", "Relaxation & Sleep Meditations", "Guided Adult Stories & Children Stories", "Sleep Medicine Articles & Guides"]} />
                  </div>
                  <div className="d-md-flex justify-content-between   w-100  flex-row d-none px-4 my-4">
                    <ListItem icon={Tick} data={["Sounds & Music", "Relaxation & Sleep Meditations",]} />
                    <ListItem icon={Tick} data={["Guided Adult Stories & Children Stories", "Sleep Medicine Articles & Guides"]} />

                  </div>
                </div>

              </div>
              {/* <Link to={'/login'} className="btn rounded-5 px-4 py-2 stop-color " style={{ backgroundColor: '#EFB729', fontSize: '12px' }}>Continue</Link> */}
              <Link to={"/login"} className="smallText goldenColor2">Web Browser Login</Link>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


const ListItem = ({ data, icon }) => {
  return (
    <ul className="listing-with-icon">
      {data.map((item, index) => (
        <li className="px-1" key={index}>
          <img src={icon} className="tick-style"  />
          <span className="">{item}</span>
        </li>
      ))}
    </ul>
  )
}

const ImageWithCaption = ({ icon, caption }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <img src={icon} />
      {caption}
    </div>
  )
}

export default DownloadApp;