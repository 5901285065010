import { createSlice } from "@reduxjs/toolkit";

const ExternalMixerSlice = createSlice({
  name: "externalmixerslice",
  initialState: {
    play: false,
  },
  reducers: {
    ExternalMixerPlaySound(state, action) {
      state.play = true;
    },
    ExternalMixerPauseSound(state, action) {
      state.play = false;
    },
  },
});

export default ExternalMixerSlice.reducer;
export const { ExternalMixerPlaySound, ExternalMixerPauseSound } = ExternalMixerSlice.actions;
