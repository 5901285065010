import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import BASEURL from "../../Config/global";
import { setAccessToken } from "../../Util/authHeader";

import { useDispatch } from "react-redux";
import { setUserDetails } from "../../Store/Slices/UserSlice";

import AuthLayout from "../../Layout/AuthLayout";
import CustomButton from "../../Components/CustomButton";
import CustomInput from "../../Components/CustomInput";

import "./style.css";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ error: false, text: "" });

  const handleClick = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.email?.trim());
    try {
      const response = await axios.post(`${BASEURL}/api/user/forget_password/`, formDataToSend);
      if (response.data.error) {
        if(response.data.message === "User does not exist"){
          setLoading(false);
          setErrorMsg({ error: true, text: response.data.message });
        }
      }else{
        setLoading(false);
        localStorage.setItem("user_email", formData.email);
        navigate("/verification");
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <AuthLayout pagename="forgot-password">
        <h3 className="lightColor text-center fw-semibold text-uppercase m-0 mb-5">Forgot Password</h3>
        <form>
          <div className="row">
            <div className="col-12">
              <CustomInput
                label="Email"
                required
                id="email"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                onChange={(event) => {
                  setErrorMsg({ error: false, text: "" });
                  setFormData({
                    ...formData,
                    email: event.target.value,
                  });
                }}
              />
            </div>
            {errorMsg.error == true && (
              <div className="col-12">
                <p className="smallText lightColor">{errorMsg.text}</p>
              </div>
            )}
          </div>
          <div className="row mt-4 text-center">
            <div className="col-12">
              {
                loading ? (

                  <button className="primaryButton customButton">
                    <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
                  </button>
                )
                  : (

                    <CustomButton
                      variant="primaryButton"
                      text="Send Email"
                      onClick={handleClick}
                    />
                  )
              }
            </div>
          </div>
        </form>
      </AuthLayout>
    </>
  );
};

export default ForgotPassword;
