import { useEffect, useRef, useState } from "react";

import MainLayout from "./../../Layout/MainLayout";
import CustomCard from "../../Components/CustomCard";

import BASEURL from "./../../Config/global";

import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
// import { playAudio } from "../../Store/Slices/AudioSlice";
import { playSound, playAudio, clearAllSound, checkSoundPlayerOrMixes, pauseMyMixes, clearAllMixer, playMyMixer, playMyMixerObj } from "../../Store/Slices/SoundPlayerSlice";

import { activeUser, userLogout } from "../../Store/Slices/APIs";
import { GetAPI } from "../../Api/ApiConfig";
import { crossIcon } from "../../Assets/svg";
import { decryptUserId } from "../../Services/Common";

import "./style.css";

import { pauseMixer, playMixer } from "../../Store/Slices/MixerSlice";
import { ExternalMix } from "../../Store/Slices/MyMixerSlice";


const Home = () => {

  let { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchField = useRef('null')

  const [searchQuery, setSearchQuery] = useState('');
  const [soundData, setSoundData] = useState([]);
  const [meditationData, setMeditationData] = useState([]);
  const [storiesData, setStoriesData] = useState([]);
  const [articlesData, setArticleData] = useState([]);
  const [greetingMessage, setGreetingMessage] = useState("Welcome");
  const checkSoundPlayerOrMixesState = useSelector((state) => state.soundPlayer.checkSoundPlayerOrMixesState);

  useEffect(() => {
    dispatch(userLogout())
  }, []);

  useEffect(() => {
    if (id) {
      getDataByApi();
    }
  }, [id]);

  const getDataByApi = async () => {
    try {
      const userId = decryptUserId(id);
      const response = await axios.get(`${BASEURL}/api/sounds/sounds_mixture/${userId}`);
      await playingMixer(response.data.data[0])
    } catch (error) {
      console.error(error);
      navigate("/404");
    }
  }

  const playingMixer = (sound) => {
    dispatch(playMyMixer(sound.sounds_list));
    dispatch(playMyMixerObj(sound));
    dispatch(ExternalMix(sound.sounds_list));
    setTimeout(() => {
      dispatch(playMixer());
    }, 500);
    setTimeout(() => {
      dispatch(pauseMixer());
    }, 1000);
  }

  const getHomePageData = async (query = null) => {
    let apiUrl = '/api/articles/all-data/'
    if (query) {
      apiUrl += `?search=${query}`
    }
    const res = await GetAPI(apiUrl)
    const { error } = res.data;
    if (error) {
      console.log("something is wrong", res.data.message)
      return
    }
    if (res.data && res.data.data) {
      const { Articles, Mediations, Sounds, Stories } = res.data.data
      setArticleData(Articles);
      setMeditationData(Mediations);
      setSoundData(Sounds);
      setStoriesData(Stories);
    }
  }

  useEffect(() => {
    try {
      getHomePageData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    var myDate = new Date();
    var hrs = myDate.getHours();
    if (hrs > 5 && hrs < 12)
      setGreetingMessage('Good Morning');
    else if (hrs >= 12 && hrs < 17)
      setGreetingMessage('Good Afternoon');
    else if (hrs >= 17 && hrs <= 20)
      setGreetingMessage('Good Evening');
    else if (hrs > 20 && hrs <= 24 || hrs < 5)
      setGreetingMessage('Good Night');
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getHomePageData(searchQuery);
    }, 500);

    // Cleanup timeout when the component unmounts or when searchQuery changes
    return () => clearTimeout(timeoutId);

  }, [searchQuery]);

  const dispatchPlaySound = (item) => {
    if (!checkSoundPlayerOrMixesState) {
      dispatch(clearAllSound());
    }
    dispatch(checkSoundPlayerOrMixes(true));
    dispatch(clearAllMixer());
    dispatch(playSound(item));
  };

  const dispatchPlayAudio = (item) => {
    if (!checkSoundPlayerOrMixesState) {
      dispatch(clearAllSound());
    }
    dispatch(checkSoundPlayerOrMixes(true));
    dispatch(playAudio(item));
  };

  return (
    <>
      <MainLayout title={greetingMessage}>
        <div className="search-bar mb-4 position-relative">
          <input
            type="search"
            className={`searchBar w-100 pe-5`}
            placeholder="What do you want to listen to?"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            ref={searchField}
            onFocus={() => searchField.current.placeholder = ""}
            onBlur={() => searchField.current.placeholder = "What do you want to listen to?"}
          />
          {
            searchQuery.length > 0 && (
              <button
                className="closeButton notButton position-absolute end-0 top-0 px-3 py-2"
                onClick={() => setSearchQuery('')}
              >
                <img src={crossIcon} alt="" />
              </button>
            )
          }
        </div>
        <div className="row mb-3 home-page-row">
          {
            (searchQuery != '' && soundData.length == 0) ? '' : (
              <>
                <div className="col-6">
                  <h2 className="sectionTitle">Sounds & Music</h2>
                </div>
                <div className="col-6 text-end">
                  <Link
                    to={"/sounds"}
                    className="smallText goldenColor2 text-decoration-none d-flex align-items-center justify-content-end ms-auto"
                  >
                    Browse Collection
                    <svg xmlns='http://www.w3.org/2000/svg' width={'15px'} viewBox='0 0 16 16' fill='#f7ba31' style={{ transform: 'rotate(270deg)' }}>
                      <path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
                    </svg>
                  </Link>
                </div>
              </>
            )
          }
          <div className="col-12">
            <div className="homeboxesWrapper">
              {
                soundData.slice(0, 20).map((item, index) => (
                  // Render your original data items here
                  <CustomCard
                    key={index}
                    title={item.title}
                    image={`${item.image}`}
                    alt={"In The News"}
                    onClick={() => {
                      dispatchPlaySound(item);
                    }}
                    item={item}
                  />
                ))
              }
            </div>
          </div>
        </div>
        <div className="row mb-3 home-page-row">
          {
            (searchQuery != '' && meditationData.length == 0) ? '' : (
              <>
                <div className="col-6">
                  <h2 className="sectionTitle">Relaxation</h2>
                </div>
                <div className="col-6 text-end">
                  <Link
                    to={"/relaxation"}
                    className="smallText goldenColor2 text-decoration-none d-flex align-items-center justify-content-end ms-auto"
                  >
                    Browse Collection
                    <svg xmlns='http://www.w3.org/2000/svg' width={'15px'} viewBox='0 0 16 16' fill='#f7ba31' style={{ transform: 'rotate(270deg)' }}>
                      <path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
                    </svg>
                  </Link>
                </div>
              </>
            )
          }
          <div className="col-12">
            <div className="homeboxesWrapper">
              {
                meditationData.slice(0, 20).map((item, index) => (
                  <CustomCard
                    key={index}
                    title={item.title}
                    image={`${item.image}`}
                    alt={"relaxation"}
                    onClick={() => {
                      dispatchPlayAudio(item);
                    }}
                    item={item}
                  />
                ))
              }

            </div>
          </div>
        </div>
        <div className="row mb-3 home-page-row">
          {
            (searchQuery != '' && storiesData.length == 0) ? '' : (
              <>
                <div className="col-6">
                  <h2 className="sectionTitle">Stories</h2>
                </div>
                <div className="col-6 text-end">
                  <Link
                    to={"/stories"}
                    className="smallText goldenColor2 text-decoration-none d-flex align-items-center justify-content-end ms-auto"
                  >
                    Browse Collection
                    <svg xmlns='http://www.w3.org/2000/svg' width={'15px'} viewBox='0 0 16 16' fill='#f7ba31' style={{ transform: 'rotate(270deg)' }}>
                      <path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
                    </svg>
                  </Link>
                </div>
              </>
            )
          }
          <div className="col-12">
            <div className="homeboxesWrapper">

              {
                storiesData.slice(0, 20).map((item, index) => (
                  <CustomCard
                    key={index}
                    title={item.title}
                    image={`${item.image}`}
                    alt={"Stories"}
                    onClick={() => {
                      dispatchPlayAudio(item);
                    }}
                    item={item}
                  />
                )
                )}
            </div>
          </div>
        </div>
        <div className="row mb-3 home-page-row">
          {
            (searchQuery != '' && articlesData.length == 0) ? '' : (
              <>
                <div className="col-6">
                  <h2 className="sectionTitle">Audio Articles</h2>
                </div>
                <div className="col-6 text-end">
                  <Link
                    to={"/articles"}
                    className="smallText goldenColor2 text-decoration-none d-flex align-items-center justify-content-end ms-auto"
                  >
                    Browse Collection
                    <svg xmlns='http://www.w3.org/2000/svg' width={'15px'} viewBox='0 0 16 16' fill='#f7ba31' style={{ transform: 'rotate(270deg)' }}>
                      <path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' />
                    </svg>
                  </Link>
                </div>
              </>
            )
          }
          <div className="col-12">
            <div className="homeboxesWrapper">
              {

                articlesData.slice(0, 20).map((item, index) => (
                  <CustomCard
                    key={index}
                    title={item.title}
                    image={`${item.image}`}
                    alt={"Articles"}
                    onClick={() => {
                      dispatchPlayAudio(item);
                    }}
                    item={item}
                  />
                ))
              }

            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Home;
