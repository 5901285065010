import { React, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router'
import { getAccessToken } from "../../Util/authHeader";
import axios from "axios";
import BASEURL from "../../Config/global";
import MainLayout from "./../../Layout/MainLayout";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Modal } from 'react-bootstrap';
import './style.css'
import { crossIcon } from '../../Assets/svg';
import CustomButton from '../../Components/CustomButton';

export const PaymentForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [paymentData, setPaymentData] = useState();
    const [succesModal, setSuccesModal] = useState(false);
    const [paymentDecline, setPaymentDecline] = useState(false);
    const [loading, setLoading] = useState(false);
    const [couponPaymentError, setCouponPaymentError] = useState({ error: false, text: "" });


    const stripe = useStripe()
    const elements = useElements()

    useEffect(() => {
        let parseData = JSON.parse(localStorage.getItem("user"));
        if (!parseData || !parseData?.verify) {
            navigate('/404');
        }
    }, [])

    async function PackageDetails() {
        try {
            const response = await axios.get(`${BASEURL}/api/user/packages/${id}`);
            if (response.data.error != true) {
                setPaymentData(response?.data?.data[0])
                // console.log(response)
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        PackageDetails()
    }, [])



    // payment form integration 

    async function signUpAPi() {
        setLoading(true);
        setCouponPaymentError({ error: false, text: "" });
        try {
            if (!stripe || !elements) {
                return;
            }
            const cardElement = elements.getElement(CardElement);
            // Create a PaymentMethod and attach it to the customer
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                setLoading(false);
                if (error.message.includes('Invalid API Key provided:')) {
                    setCouponPaymentError({ error: true, text: 'Invalid Card Details' });
                } else {
                    setCouponPaymentError({ error: true, text: 'Invalid Card Details' });
                    // setCouponPaymentError({ error: true, text: error.message });
                    setPaymentDecline(true);
                    setTimeout(() => {
                        setPaymentDecline(false);
                    }, 2500);
                    console.error('Error creating PaymentMethod:', error.message.includes('Invalid API Key provided:'));
                }
            }
            else {
                let parseData = JSON.parse(localStorage.getItem("user"));
                const userEmail = parseData.email;
                const productId = paymentData?.package_price_id;
                const planId = paymentData?.plan_id;

                const formData = new FormData();
                formData.append('email', userEmail);
                formData.append('plan_id', planId);
                if (parseData.subscription_status !== 'ReSubscription_Failed') {
                    formData.append('trial_period', "14");
                }
                formData.append('platform', "web");
                formData.append('paymentMethodId', paymentMethod.id);

                const response = await axios.post(`${BASEURL}/api/user/customersubscription/`, formData);
                // const customerId = response?.data?.data?.customer_id;

                if (!response.data.error) {
                    setLoading(false);
                    if (response?.data?.data?.subscription_status === 'incomplete') {
                        setCouponPaymentError({ error: true, text: 'Invalid Card Details' });
                    } else {
                        setSuccesModal(true);
                        AfterPaymentApi();
                        setTimeout(() => {
                            setSuccesModal(false);
                            navigate('/download-app');
                        }, 2500);
                    }
                } else {
                    setLoading(false);
                    setCouponPaymentError({ error: true, text: 'Invalid Card Details' });
                }
            }

        } catch (error) {
            console.error('Error:', error);
            // Handle the error appropriately, e.g., display an error message to the user.
        }
    }

    const AfterPaymentApi = () => {
        let parseData = JSON.parse(localStorage.getItem("user"));
        const userId = parseData.id;
        const productId = paymentData?.id;
        console.log(productId)
        const formDataToSend = new FormData();
        formDataToSend.append("user_id", userId);
        formDataToSend.append("package_id", productId);
        try {
            const response = axios.post(
                `${BASEURL}/api/user/package_done`,
                formDataToSend
            );
        } catch (error) {
            console.error(error);
        }
    }

    // const handleSubmit = event => {
    //     event.preventDefault()
    //     signUpAPi()

    //     setTimeout(function () {
    //        
    //     }, 5000)
    // }

    return (
        <div className="mainWrapper" style={{ height: '100vh' }}>
            <div className="container-fluid pt-5">
                {/* <div className="row pt-4 pb-lg-5 pb-4">
                    <div className="col-12 text-center">
                        <h2 className="pageTitle">
                            Payment Method
                        </h2>
                    </div>
                </div> */}
                <div className="row">
                    <div className='col-md-6 m-auto'>
                        <div className='coupon-payment-wrapper p-4 mt-5 text-center'>
                            <h2 className="pageTitle mb-5">Enter Payment Method</h2>
                            {/* <div className='packageFormInfo text-center'>
                                <h3 className='text-capitalize lightColor fs-4 mb-2'>{paymentData?.title}</h3>
                                <h5 className='lightColor fs-5 mb-2'>{`$ ${paymentData?.price}`}</h5>
                            </div>
                            <div className='paymentDescription text-center'>
                                <p className='lightColor fs-6 mb-2'>{paymentData?.description} (Free 14 Day Trial)</p>
                            </div> */}
                            <div className='cardBox my-4 mt-5'>
                                <CardElement />
                                {
                                    couponPaymentError.error && <p className='my-2 text-danger'>{couponPaymentError.text}</p>
                                }
                            </div>
                            {/* <div className='payNow text-center mt-5'>
                        <button className="customButton primaryButton" onClick={signUpAPi}>
                            Pay Now
                        </button>
                    </div> */}

                            {
                                loading ? (
                                    <div className='payNow text-center mt-4'>
                                        <button class="primaryButton customButton">
                                            <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
                                        </button>
                                    </div>
                                )
                                    : (
                                        <div className='payNow text-center mt-4'>
                                            <CustomButton
                                                variant="primaryButton"
                                                text="Save"
                                                onClick={signUpAPi}
                                            />
                                        </div>
                                    )
                            }
                        </div>

                    </div>

                </div>

                {/* success modal */}
                <Modal
                    show={succesModal}
                    centered
                    className="success-modal"
                    backdrop="static"
                >
                    <Modal.Body>
                        <div className="text-end ml-auto">
                            {/* <button
                            className="closeButton notButton ms-auto"
                            onClick={() => setSuccesModal(false)}
                        >
                            <img src={crossIcon} alt="" />
                        </button> */}
                        </div>
                        <div className="p-5">
                            <h2 className="font-16 lh-16 my-5 fw-normal text-center">Saved Successfully, <br /> Please Login</h2>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={paymentDecline}
                    centered
                    className="success-modal"
                    backdrop="static"
                >
                    <Modal.Body>
                        <div className="text-end ml-auto">
                            {/* <button
                            className="closeButton notButton ms-auto"
                            onClick={() => setPaymentDecline(false)}
                        >
                            <img src={crossIcon} alt="" />
                        </button> */}
                        </div>
                        <div className="p-5">
                            <h2 className="font-16 lh-16 my-5 fw-normal text-center">Payment Declined!</h2>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}
