import axios from "axios";
import { connection_string } from "./connection_strings";
import { getAccessToken, removeAccessToken } from "./authHeader";
import { Modal } from "react-bootstrap";
import BASEURL from "../Config/global";
import { crossIcon } from "../Assets/svg";
import BASEURLFrontend from "../Config/urls";
window.axios = axios;
axios.defaults.baseURL = connection_string;
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// const navigate = useNavigate();
axios.interceptors.request.use(
  (config) => {
    config.headers["ip"] = localStorage.getItem('ip');
    const token = getAccessToken();
    if (token) {
      // console.log("token", token);
      config.headers["Authorization"] = 'Token ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

let counter = 0;
// Adds a response interceptor
axios.interceptors.response.use(
  function (response) {
    if (response.data.error) {
      if (response.data.message === "Your_subscription_has_been_expired.") {

        const modalWrap = document.createElement('div');
        const modal = document.createElement('div');

        modalWrap.classList.add("household-modal");
        modal.classList.add("household-modal-inner");

        const span = document.createElement('span');
        span.classList.add("closeButton");
        // span.innerHTML = 'X';

        const img = document.createElement('img');
        img.src = crossIcon;
        span.appendChild(img);

        const para = document.createElement('p');
        para.innerHTML = "You've been away from your primary household account for over 30 days.";
        para.classList.add('mb-4', 'lh-16', 'font-16')

        const para1 = document.createElement('p');
        para1.innerHTML = 'You have two options:';
        para1.classList.add('mb-4', 'lh-16', 'font-16')

        const para2 = document.createElement('p');
        para2.innerHTML = '1. Log in from your primary household location to re-verify your sub account for another 30 days.';
        para2.classList.add('mb-4', 'lh-16', 'font-16')

        const para4 = document.createElement('p');
        para4.innerHTML = '2. Create your primary account with a monthly or yearly subscription ';
        para4.classList.add('mb-4', 'lh-16', 'font-16')

        const anchor1 = document.createElement('a');
        anchor1.href = BASEURLFrontend + '/subscription';
        
        anchor1.innerHTML = 'Click here';
        anchor1.classList.add('lh-16', 'font-16');

        para4.appendChild(anchor1);

        const para5 = document.createElement('p');
        para5.innerHTML = 'For details visit: ';
        para5.classList.add('lh-16', 'font-16');

        // const para6 = document.createElement('p');
        // para6.innerHTML = 'For details visit: ';

        const anchor2 = document.createElement('a');
        anchor2.innerHTML = 'RelaxScofa.com/faq';
        anchor2.href = 'https://relaxscofa.com/faq/#sub-accounts';
        anchor2.classList.add('lh-16', 'font-16');

        // para5.appendChild(anchor2);

        modal.appendChild(span);
        modal.appendChild(para);
        modal.appendChild(para1);
        modal.appendChild(para2);
        // modal.appendChild(para3);
        modal.appendChild(para4);
        // modal.appendChild(anchor1);
        modal.appendChild(para5);
        modal.appendChild(anchor2);

        modalWrap.appendChild(modal);
        document.body.appendChild(modalWrap);


        span.addEventListener('click', () => {
          removeAccessToken();
          localStorage.removeItem("user");
          localStorage.removeItem("is_primary");
          localStorage.removeItem("user_email");
          localStorage.removeItem("ip");
          localStorage.removeItem("userId");
          window.location = '/login';
        })

        anchor1.addEventListener('click', (e) => {
          e.preventDefault();
          removeAccessToken();
          window.location = '/subscription';
          // localStorage.removeItem("user");
          // localStorage.removeItem("is_primary");
          // localStorage.removeItem("user_email");
          // localStorage.removeItem("ip");
          // localStorage.removeItem("userId");
        })

      }
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;
