import React, { useState } from "react";
import ReactPlayer from "react-player/file";
import BASEURL from '../../Config/global';
import { LockBadge } from "../../Assets/svg";

const RelaxPlayer = ({ item }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        setIsPlaying(!isPlaying);
    };

    return (
        <div>

            <div className={`${(isPlaying) && 'gradient'} roundAudioWrapper`}>
                <div
                    className={`roundAudio ${item.id}`}
                    onClick={togglePlay}
                >
                    <div className="imageWrapper">
                        <img
                            src={`${item.tumbnail ? 'https://backend.relax.scofa.com' + item.tumbnail : 'https://backend.relax.scofa.com' + item.thumbnail}`}
                            alt="Sound Thumbnail"
                        />
                    </div>
                    <p>{item.title}</p>
                    {item.premium && (
                        <span className="premiumBadge">
                            <LockBadge />
                        </span>
                    )}
                </div>
            </div>
            <div className="d-none">
                <ReactPlayer
                    url={'https://backend.relax.scofa.com' + item.audio}
                    playing={isPlaying}
                    controls={true}
                    loop={true}
                    height="60px"
                />
            </div>
        </div>
    );
}

export default RelaxPlayer;