import React, { useEffect, useState } from 'react'
import BASEURL from '../../Config/global';
import axios from 'axios';
import RelaxPlayer from '../../Components/RelaxPlayer';
import MainLayout from '../../Layout/MainLayout';

const VideoPlayer = () => {
    const [soundsData, setSoundsData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(`https://backend.relax.scofa.com/api/sounds/`);
                if (!response.data.error) {
                    console.log(response.data.data);
                    setSoundsData(response.data.data);
                } else {
                    console.log(response.data.message);
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);

    return (
        <MainLayout>
            <div className='d-flex flex-wrap gap-3'>
                {soundsData.map((item, index) => (
                    <RelaxPlayer key={index} item={item} />
                ))}
            </div>
        </MainLayout>
    )
}

export default VideoPlayer