import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import BASEURL from "../../Config/global";
import { setAccessToken } from "../../Util/authHeader";

import { useDispatch } from "react-redux";
import { setUserDetails } from "../../Store/Slices/UserSlice";

import AuthLayout from "../../Layout/AuthLayout";
import CustomButton from "../../Components/CustomButton";
import CustomInput from "../../Components/CustomInput";

import "./style.css";
import { Modal } from "react-bootstrap";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [succesModal, setSuccesModal] = useState(false);

  const handleClick = async (e) => {
    setLoading(false)
    e.preventDefault();
    const formDataToSend = new FormData();
    const userEmail = localStorage.getItem("user_email");

    formDataToSend.append("email", userEmail?.trim());
    formDataToSend.append("password", formData.password);

    try {

      if (formData.password.length > 0) {
        const response = await axios.post(`${BASEURL}/api/user/reset_password/`, formDataToSend);
        if (!response.data.error) {
          setLoading(false)
          setSuccesModal(true);
          setTimeout(() => {
            setSuccesModal(false);
            navigate("/login");
          }, 1000);
        } else {
          setLoading(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <AuthLayout pagename="forgot-password">
        <h3 className="lightColor text-center fw-semibold text-uppercase m-0 mb-5">Reset Password</h3>
        <form>
          <div className="row">
            <div className="col-12">
              <CustomInput
                label="Password"
                required
                id="password"
                type="password"
                labelClass="mainLabel"
                inputClass="mainInput"
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    password: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row mt-4 text-center">
            <div className="col-12">
              {
                loading ? (

                  <button className="primaryButton customButton">
                    <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
                  </button>
                )
                  : (

                    <CustomButton
                      variant="primaryButton"
                      text="Submit"
                      onClick={handleClick}
                    />
                  )
              }

            </div>
          </div>
        </form>
      </AuthLayout>

      {/* Success Modal */}
      <Modal
        show={succesModal}
        centered
        className="success-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="my-5">
            <h2 className="font-16 lh-16 fw-normal my-5 text-center">Your password is Reset!</h2>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default ResetPassword;
