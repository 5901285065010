import { Link } from "react-router-dom";
import { logo, googlePlay, appStore } from "../../Assets/images";

import "./style.css";

const AuthLayout = (props) => {
  return (
    <>
      <section className="fullPageBg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={`fullPage`}>
                <div className="row text-center mt-4 g-0">
                  <div className="col-12">
                    <div className="logo mt-3 mb-4">
                      <img src={logo} alt="Logo" />
                    </div>
                  </div>
                </div>
                <div className={`${props.pagename === "forgot-password" ? 'w-100 mt-5' : (props.pagename !== "signup") && (props.pagename !== "login") ? 'mt-5' : 'mt-sm-5'} row mb-2 g-0`}>
                  <div className="col-xl-6 col-lg-8 col-md-10 mx-auto">
                    <div className={`${props.pagename} authFormWrapper`}>
                      <div className={!props.login && (props.signup) ? 'mb-5 text-center' : "authHeader"}>
                        {props.signup ? (
                          <p className="lightColor smallText fw-semibold text-uppercase m-0">
                            Register
                          </p>
                        ) : props.login && (<></>
                          // <p className="lightColor smallText fw-semibold text-uppercase m-0">
                          //   Login
                          // </p>
                        )}
                        {(props.signup) && <h2 className="authTitle">Welcome</h2>}
                      </div>
                      {props?.children}
                      <br />
                      {props.signup ? (
                        <p className="authPara mt-5">
                          Already have an account?{" "}
                          <Link to={"/login"} className="authParaLink">
                            Sign In
                          </Link>
                        </p>
                      ) : props.login && (
                        <p className="authPara mt-5">
                          Don't have an account?{" "}
                          <Link to={"/freetrial"} className="authParaLink">
                            Sign Up
                          </Link>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row g-0">
                  <div className="col-12">
                    <div className="authFooter">
                      {(props.signup || props.login) && (
                        <div className="authFooterLinks">
                          <Link to={"https://relaxscofa.com/terms-of-service/"}>Terms of Service</Link>
                          <span>|</span>
                          <Link to={"https://relaxscofa.com/privacy-policy/"}>Privacy Policy</Link>
                        </div>
                      )}
                      {/* <div className="appLinks">
                        <Link to={"#_"} className="appLink">
                          <img src={googlePlay} alt="Google Play" />
                        </Link>
                        <Link to={"#_"} className="appLink">
                          <img src={appStore} alt="App Store" />
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AuthLayout;