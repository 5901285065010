import { createSlice } from "@reduxjs/toolkit";
import BASEURL from "../../Config/global";

const audioSlice = createSlice({
  name: "audio",
  initialState: {
    audioId: null,
    audioSource: null,
    audioTitle: null,
    audioThumbnail: null,
    howlAudioPlay: false,
    seekTime: null,
    audioStopTime: 1,
    audioDurationCurrentTime: 1,
  },
  reducers: {
    playAudio(state, action) {
      const { id, source, title, thumbnail } = action.payload;
      return {
        audioId: id,
        audioSource: BASEURL + source,
        audioTitle: title,
        audioThumbnail: BASEURL + thumbnail,
      };
    },
    AudioDuration(state, action) {
      state.howlAudioPlay = true;
    },
    AudioSeek(state, action) {
      state.audioStopTime = action.payload; 
      state.seekTime = action.payload;
    },
    AudioDurationClose(state, action) {
      state.howlAudioPlay = false;
    },
    AudioDurationStop(state, action) {
      state.audioStopTime = action.payload; 
    },
    AudioDurationCurrentTime(state, action) {
      // console.log(action.payload)
      state.audioDurationCurrentTime = action.payload; 
    },
  },
});

export default audioSlice.reducer;
export const { playAudio, AudioDuration, AudioSeek, AudioDurationClose, AudioDurationStop, AudioDurationCurrentTime } = audioSlice.actions;
