import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { playAudio } from '../../Store/Slices/SoundPlayerSlice';

import BASEURL from '../../Config/global';

import { FillPlay, FillPause } from '../../Assets/svg';

import { pauseMixer, playMixer } from '../../Store/Slices/MixerSlice';
import './style.css'
import { Link } from 'react-router-dom';
import { activeUser } from '../../Store/Slices/APIs';

const CustomAudioCard = ({ item }) => {

    const dispatch = useDispatch();
    const audio = useSelector((state) => state.soundPlayer.audio);
    const isPlaying = useSelector((state) => state.mixer.play);

    const [isPlayingState, setIsPlayingState] = useState(false)
    const [isActiveAudio, setIsActiveAudio] = useState(false)

    useEffect(() => {
        if (audio.audio == item.audio) {
            setIsActiveAudio(true)
        }
        else {
            setIsActiveAudio(false)
        }
    }, [audio])

    useEffect(() => {
        if (audio.audio == item.audio && isPlaying) {
            setIsPlayingState(true)
        }
        else {
            setIsPlayingState(false)
        }
    }, [audio])

    useEffect(() => {
        if (isPlaying) {
            setIsPlayingState(true)
        }
        else {
            setIsPlayingState(false)
        }
    }, [isPlaying])

    // console.log(audio)


    // const handlePlay = () => {
    //     if (audio.audio == item.audio) {
    //         dispatch(playMixer());
    //         setIsPlayingState(true)
    //     }
    //     else {
    //         dispatch(playAudio(item));
    //         dispatch(playMixer());
    //         setIsPlayingState(true)
    //     }

    // };

    // const handlePause = () => {
    //     dispatch(pauseMixer());
    //     setIsPlayingState(false)
    // };

    const handlePlay = () => {
        if (isActiveAudio) {
            if (isPlaying) {
                dispatch(pauseMixer());
                setIsPlayingState(false);
            } else {
                dispatch(playMixer());
                setIsPlayingState(true);
            }
        } else {
            dispatch(playAudio(item));
            dispatch(playMixer());
            setIsPlayingState(true);
            dispatch(activeUser());
        }
    };


    return (
        <div className="custom-card-wrapper">
            <div
                className="custom-card"
            >
                <img
                    src={`${item?.image}`}
                    className="card-image"
                />
                {/* {isPlayingState ? (<span className="button" onClick={handlePause}>
                    <img
                        src={FillPause}
                    />

                </span>) : (<span className="button" onClick={handlePlay}>
                    <img
                        src={FillPlay}
                    />

                </span>)} */}

                <span className="button" onClick={handlePlay}>
                    {isPlayingState && isActiveAudio ? (
                        <img src={FillPause} />
                    ) : (
                        <img src={FillPlay} />
                    )}
                </span>
            </div>
            <Link
                to={`/audio-detail/${item.type}/${item.id}`}
                className="">
                <h3 className="card-title mt-3 mb-1">{item?.title}</h3>
            </Link>

        </div>
    )
}

export default CustomAudioCard
