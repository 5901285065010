import { Link } from "react-router-dom";
import { logo, zero } from "../../Assets/images";

const PageNotFound = () => {

    return (
        <section className="mainWrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="mainCategories">
                            <div className="logo mb-5 mt-2 mt-sm-5">
                                <Link to={'/'}>
                                    <img src={logo} alt="Logo" />
                                </Link>
                            </div>
                            <div className="mt-2 mt-sm-5">
                                <h1 className="d-flex align-items-center fw-bold" style={{ fontSize: '120px' }}>4<img width={100} src={zero} alt="Logo" />4</h1>
                            </div>
                            <div className="mb-5 mt-2 mt-sm-5 text-center">
                                <h4 className="d-flex align-items-center justify-content-center">Please go</h4>
                                <h4 className="d-flex align-items-center justify-content-center">to <Link className="ps-2" to={'/'}>Home Page</Link></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageNotFound