import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userDetails: null,
    signupResponse:null
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setSignUpResponse:(state, action)=>{
      state.signupResponse = action.payload
    }
  },
});

export default userSlice.reducer;
export const { setUserDetails, setSignUpResponse } = userSlice.actions;
