import { Link } from "react-router-dom";

import { logo } from "../../Assets/images";
import {
  CheckSymptomsIcon,
  ArticlesIcon,
  FindDoctorsIcon,
  StoreIcon,
} from "./../../Assets/svg";

import "./style.css";

const Main = () => {
  return (
    <>
      <section className="mainWrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="mainCategories">
                {/* <div className="row text-center my-4"></div> */}
                <div className="row text-center py-5">
                  <div className="col-12">
                    <div className="logo mb-5 mt-2 mt-sm-5">
                      <img src={logo} alt="Logo" />
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <h2 className="mainCategoryTitle mb-lg-4 mb-2">
                      Choose Your Category
                    </h2>
                  </div> */}
                  <div className="col-12">
                    <Link
                      to={"/login"}
                      className="customButton primaryButton mt-3 mt-sm-5"
                    >
                      Get Started
                    </Link>
                  </div>
                  <div className="col-12 mt-4">
                    <span className="categoryLink no-border no-padding no-bg cursor-default fw-bold">
                      Enjoy!
                    </span>
                  </div>
                  <div className="col-12 mt-2">
                    <span className="categoryLink no-border no-padding no-bg cursor-default">
                      Sounds & Music
                    </span>
                  </div>
                  <div className="col-12">
                    <span className="categoryLink no-border no-padding no-bg cursor-default">
                      Relaxation
                    </span>
                  </div>
                  <div className="col-12">
                    <span className="categoryLink no-border no-padding no-bg cursor-default">
                      Stories
                    </span>
                  </div>
                  <div className="col-12">
                    <span className="categoryLink no-border no-padding no-bg cursor-default">
                      Audio Articles
                    </span>
                  </div>
                </div>
                <div className="row w-100 mt-sm-auto mb-sm-5">
                  <div className="col-12">
                    <div className="d-flex flex-column-reverse flex-lg-row justify-content-center gap-3 py-3 mb-5">
                      <div className={`d-flex gap-3 justify-content-center align-items-center`}>
                        <Link className={`externalLink  ${window.innerWidth < 575 ? 'lightColor' : ''}`} to={"https://relaxscofa.com/terms-of-service/"}>Terms of Service</Link>
                        <span className={`externalLink  ${window.innerWidth < 575 ? 'lightColor' : ''}`}>|</span>
                        <Link className={`externalLink  ${window.innerWidth < 575 ? 'lightColor' : ''}`} to={"https://relaxscofa.com/privacy-policy/"}>Privacy Policy</Link>
                      </div>
                      <div className="d-flex gap-3 my-5 my-sm-0 justify-content-center align-items-center">
                        <Link to={"https://store.scofa.com/"} className="externalLink">
                          <img src={StoreIcon} className="" />
                          Sleep Store
                        </Link>
                        <Link to={"https://scofa.com/search-for-sleep-provider/"} className="externalLink">
                          <img src={FindDoctorsIcon} className="" />
                          Sleep Doctors
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
