import CryptoJS from 'crypto-js';

// Function to format the phone number
const formatPhoneNumber = (value) => {
    if (!value) return value;
    // Remove all non-digit characters
    const phoneNumber = value.replace(/[^\d]/g, "");
    // Format the phone number
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

// Function to check the validate birthdate
const validateBirthDate = (value) => {
    if (!value || value.length !== 10) {
        return false;
    } else {
        return true;
    }
};

const decryptUserId = (decrypted) => {
    const key = 'secret-key'; // Replace with your secret key
    const decryptedId = decrypted?.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');
    const decryptedText = CryptoJS.AES.decrypt(decryptedId, key);
    decrypted = decryptedText?.toString(CryptoJS.enc.Utf8);
    return decrypted;
};

export { formatPhoneNumber, validateBirthDate, decryptUserId }; 