import React, { useState } from 'react'
import { AppStore, GooglePlay, Membership } from '../../Assets/images'
import { Tick, crossIcon } from "../../Assets/svg";
import './style.css'
import { useNavigate } from 'react-router';

const ListItem = ({ data, icon }) => {
    return (
        <ul className="listing-with-icon">
            {data.map((item, index) => (
                <li className=" " key={index}>
                    <img src={icon} className="tick-style" />
                    <span className="trial-list-item-text">{item}</span>
                </li>
            ))}
        </ul>
    )
}

const AppDownloadTray = ({ openState, handleCallBack }) => {

    const navigate = useNavigate();

    return (
        <div className={`information-tray app-download-tray ${openState && 'show'}`}>
            <div className="scrollbar">
                <div className="header position-relative d-flex justify-content-center align-items-center" style={{ height: '80px' }}>
                    <h5 className='mb-0'>iOS/Android Apps</h5>
                    <button className="closeButton notButton ms-auto d-flex p-3" onClick={() => { handleCallBack(false) }}>
                        <img src={crossIcon} alt="" />
                    </button>
                </div>
                <div className='px-3'>
                    <p className='text-white text-center my-3' style={{ fontSize: '15px' }}>Install the Android and iOS app.</p>
                    <div className='d-flex flex-column align-items-center justify-content-center gap-1'>

                        <div className="d-flex align-items-center justify-content-center  gap-4">
                            <div className='text-center'>
                                {/* <p className='fw-bold  mb-2' style={{ fontSize: '16px !important', color: '#EFB729' }}>Coming Soon</p> */}
                                <button type='button' className="btn bg-white" style={{ height: '45px' }} onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.scofarelax&hl=en_US'}>
                                    <img src={GooglePlay} alt="Google Play" width={120} />
                                </button>
                            </div>
                            <div className='text-center'>
                                {/* <p className='fw-bold  mb-2' style={{ fontSize: '16px !important', color: '#EFB729' }}>Available Now</p> */}
                                <button type='button' className="btn bg-white" style={{ height: '45px' }} onClick={() => window.location.href = 'https://apps.apple.com/us/app/relax-scofa/id6476312399'}>
                                    <img src={AppStore} alt="App Store" width={120} />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="display-image overflow-hidden">
                                <img src={Membership} className="img-fluid membership-image" />
                            </div>
                            <div className="px-4 mt-4">
                                <ListItem icon={Tick} data={["Adjust Individual Track Volumes", "Available for iOS and Android Phones", "Available for iPads and Tablets"]} />
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>

    )
}

export default AppDownloadTray