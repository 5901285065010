/**
    * @description      : 
    * @author           : Saif
    * @group            : 
    * @created          : 17/10/2023 - 23:35:13
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 17/10/2023
    * - Author          : Saif
    * - Modification    : 
**/
import { useEffect, useState } from "react";

import MainLayout from "./../../Layout/MainLayout";
import CustomCard from "../../Components/CustomCard";

import { sounds, meditation, stories, articles } from "../../Config/data";

import BASEURL from "./../../Config/global";

import "./style.css";
import { Link } from "react-router-dom";
import axios from "axios";

import { useDispatch } from "react-redux";
// import { playAudio } from "../../Store/Slices/AudioSlice";
import { playSound, playAudio, specialsCounter } from "../../Store/Slices/SoundPlayerSlice";
import CustomSearchBar from "../../Components/CustomSearchBar";


import { isMobileSafari, isSafari, isIOS } from 'react-device-detect'
import { userLogout } from "../../Store/Slices/APIs";




const Specials = () => {
  const dispatch = useDispatch();
  const [specials, setSpecials] = useState([]);


  useEffect(() => {
    dispatch(userLogout())
  }, [])


  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${BASEURL}/api/user/specials`);
        if (response.data.error != true) {
          console.log(response)
          setSpecials(response.data.data);
          // console.log(response)
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <MainLayout title="Specials" arrow>
        <div className="row mb-3 specials-banners">
          {
            specials && specials.map((item, index) => (
              item.is_new ? (
                <div class="col-md-4 mb-3">
                  <a href={item.link} target={item.is_new ? '_blank' : ''} className="bgRemove">
                    <CustomCard
                      key={index}
                      image={`${item.banner}`}
                      alt={"In The News"}
                    />
                  </a>
                </div>
              ) : (
                <div className="col-md-4 mb-3">
                  <Link to={item.link} className="bgRemove">
                    <CustomCard
                      key={index}
                      image={`${item.banner}`}
                      alt={"In The News"}
                    />
                  </Link>
                </div>
              )

            ))
          }
        </div>
      </MainLayout>
    </>
  );
};

export default Specials;
