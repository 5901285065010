import { createSlice } from "@reduxjs/toolkit";
import BASEURL from "../../Config/global";
import axios from "axios";
import { removeAccessToken } from "../../Util/authHeader";

const audioSlice = createSlice({
	name: "APIS",
	initialState: {
		state: null,
	},
	reducers: {
		userLogout(state, action) {
			const myFunc = async () => {
				try {
					const currentUser = localStorage.getItem("user");
					const parsedUser = JSON.parse(currentUser);
					const response = await axios.get(`${BASEURL}/api/user/check-user-token/${parsedUser.id}/`);
					// console.log(response)
					if (response.data.status === 500) {
						removeAccessToken();
						localStorage.removeItem("user");
						localStorage.removeItem("is_primary");
						localStorage.removeItem("user_email");
						localStorage.removeItem("ip");
						localStorage.removeItem("userId");
						window.location = '/login';
					} else {
						// console.log(response.data.message);
					}
				} catch (error) {
					console.error(error);
				}
			}
			myFunc();
		},
		activeUser(state, action) {
			const myFunc = async () => {
				try {
					const user = JSON.parse(localStorage.getItem("user"));
					const response = await axios.post(`${BASEURL}/api/user/active-user-status/${user.id}/`);
				} catch (error) {
					console.error(error);
				}
			}
			myFunc();
		},
	},
});

export default audioSlice.reducer;

export const { userLogout, activeUser } = audioSlice.actions;
