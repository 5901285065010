import MainLayout from "./../../Layout/MainLayout";
import SoundsAccordian from "../../Components/SoundsAccordian";
// css
import "./style.css";

const Sounds = () => {

  return (
    <>
      <MainLayout title="Sounds & Music">
        <SoundsAccordian list="sound page" />
      </MainLayout>
    </>
  );
};

export default Sounds;
