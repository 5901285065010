import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import MainLayout from "./../../Layout/MainLayout";

import "./style.css";
import { getAccessToken } from "../../Util/authHeader";
import axios from "axios";
import BASEURL from "../../Config/global";
import { Membership } from "../../Assets/images";
import { Tick } from "../../Assets/svg";


const ListItem = ({ data, icon }) => {
  return (
    <ul className="listing-with-icon">
      {data.map((item, index) => (
        <li className=" " key={index}>
          <img src={icon} className="tick-style" />
          <span className="trial-list-item-text">{item}</span>
        </li>
      ))}
    </ul>
  )
}

const Upgrade = () => {
  const [membership, setMembership] = useState({})
  const [selected, setSelected] = useState(0)
  const token = getAccessToken();
  const navigate = useNavigate();

  useEffect(() => {
    let parseData = JSON.parse(localStorage.getItem("user"));
    if (!parseData || !parseData?.verify) {
      navigate('/404');
    }
  }, [])

  useEffect(() => {
    getMemberShip();
  }, [])

  const getMemberShip = async () => {
    try {
      const response = await axios.get(`${BASEURL}/api/user/packages`);
      if (!response.data.error) {
        const data = response?.data?.data.sort((a, b) => {
          if (a.interval === 'year' && b.interval !== 'year') return -1;
          if (a.interval !== 'year' && b.interval === 'year') return 1;
          return 0;
        });
        setMembership(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getSubscriptionClick = async (id) => {
    const userId = localStorage.getItem("userId");
    const data = {
      user_id: userId,
      package_id: id,
    }
    try {
      const response = await axios.post(`${BASEURL}/api/user/add_subscription`, data);
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className="fullPageBg d-flex flex-column align-items-center justify-content-center h-100 py-5" style={{ minHeight: '100vh' }}>
        <div className="container-lg mb-2 mb-sm-4">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="membership-title">Create the Ideal Relaxation and Sleep Ambiance</h1>
              <h6 className="membership-sub-title">Enhance Your Wellbeing and Mental State</h6>
            </div>
          </div>
        </div>
        <div className='container-lg membership-container'>
          <div className="row">
            <div className="col-md-6">
              <div className="my-md-5">
                <img src={Membership} className="img-fluid membership-image" />
              </div>
              <div className="d-none d-md-block">
                <ListItem icon={Tick} data={["Sounds & Music", "Relaxation & Sleep Meditations", "Guided Adult Stories & Children Stories", "Sleep Medicine Articles & Guides"]} />
              </div>
            </div>
            <div className="col-md-6 align-self-center mt-3 mt-sm-4">
              <div className="w-100 mx-auto ms-md-start" style={{ maxWidth: '597px' }}>
                <div className="mb-sm-5 mb-4 d-md-none">
                  <ListItem icon={Tick} data={["Sounds & Music", "Relaxation & Sleep Meditations", "Guided Adult Stories & Children Stories", "Sleep Medicine Articles & Guides"]} />
                </div>
                {membership.length > 0 && membership?.map((item, index) => (
                  <div className={`membership-card ${selected == index && 'selected'}`} onClick={() => setSelected(index)} key={index}>
                    {(selected == 0 && index == 0) && <span className="tag">Best Value</span>}
                    <div className="flex-grow-1">
                      <h4 className="mb-0">Free 14 Day Trial</h4>
                      <p className="mb-0">{(item?.interval == 'year') ? `$${Number(item.price / 12).toString().slice(0, 4)}/month, billed yearly at $${item.price}` : `$${item.price}/monthly`}</p>
                    </div>
                    <div>
                      <button type="button" onClick={() => { navigate(`/package-details/${item?.id}`); setSelected(index) }}>Select</button>
                    </div>
                  </div>
                ))}
                <div className="mt-2 mt-sm-4 text-left">
                  <p className="membership-msg px-3 mb-0">After the free trial period ends, your subscription will start and renew on the same day each month or year, depending on the subscription you choose.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid d-none">
          <div className="row pt-5 pb-lg-5 mb-4">
            <div className="col-12 text-center">
              <h2 className="pageTitle mt-4">
                {/* Select Your <br /> */}
                Membership Plans
              </h2>
            </div>
          </div>
          <div className="row justify-content-center">
            {
              membership?.data && membership?.data.map((item, index) => (
                <div className="col-md-4 mb-3 mx-atuo" key={index}>
                  <div className="memberShipCard">
                    <p className="goldenColor mb-2">Free 14 Day Trial</p>
                    <h3 className="mb-2">{item?.title}</h3>
                    <h3 className="mb-2">{(item?.price) ? `$ ${item?.price}` : ''}</h3>
                    <Link className="customButton primaryButton mt-3" to={`/package-details/${item?.id}`}>Select</Link>
                  </div>
                </div>
              ))
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default Upgrade;
