import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import MainLayout from "./../../Layout/MainLayout";

import "./style.css";
import { getAccessToken } from "../../Util/authHeader";
import axios from "axios";
import BASEURL from "../../Config/global";
import { logo, Membership } from "../../Assets/images";
import { Tick } from "../../Assets/svg";




const Upgrade = () => {


    return (
        <>
            <div className="fullPageBg d-flex flex-column align-items-center justify-content-center h-100 py-3" style={{ minHeight: '100vh' }}>
                <div className="free-trial logo d-flex align-items-center justify-content-center my-1">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="container-lg mb-2 mb-sm-4">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className="membership-title">Create the Ideal Relaxation and Sleep Ambiance</h1>
                            <h6 className="membership-sub-title">Enhance Your Wellbeing and Mental State</h6>
                        </div>
                    </div>
                </div>
                <div className='container-lg membership-container '>
                    <div className="row">
                        <div className="col-md-6  ml-auto ml-md-0">
                            <div className="my-md-5">
                                <img src={Membership} className="img-fluid membership-image" />
                            </div>
                        </div>
                        <div className="col-md-6  col-xl-5 align-self-center mt-3 mt-sm-4">
                            <div className="w-100 mx-auto ms-md-start" style={{ maxWidth: '597px' }}>
                                <div className="row mb-sm-5 mb-4 mt-3 mt-md-0 ms-lg-4">
                                    <div className="col-11 col-md-12 col-xl-12 mx-auto">
                                        <div className="ps-md-0 px-4">
                                        <ListItem icon={Tick} data={["Sounds & Music", "Relaxation & Sleep Meditations", "Guided Adult Stories & Children Stories", "Sleep Medicine Articles & Guides"]} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-md-3 px-md-2 mx-1 "  >
                                    <div className="col-12 col-xl-10">
                                        <Link to="/signup" style={{textDecoration:'none'}}>
                                        <div className={`membership-card-free  py-4 clickable-area `}>
                                            <div className="text-center ">
                                                <h4 className="mb-2" style={{ color: '#EFB729' }}>Free 14 Day Trial</h4>
                                                <p className="mb-0">Cancel Anytime</p>
                                            </div>

                                        </div>
                                        </Link>
                                        <div className="mt-4 text-center  ">
                                            <p className="text-line-height">$4.99/Month, Billed Yearly At $59.99</p>
                                            <p className="text-line-height">Or $8.99 Billed Monthly </p>
                                            <div className="d-flex justify-content-center pt-2 pt-ms-4">
                                                <Link to={"/signup"} className="btn rounded-5 px-4 py-2 stop-color " style={{ backgroundColor: '#EFB729', fontSize: '12px' }}>Continue</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


        </>
    );
};
const ListItem = ({ data, icon }) => {
    return (
        <ul className="listing-with-icon">
            {data.map((item, index) => (
                <li className=" " key={index}>
                    <img src={icon} className="tick-style" />
                    <span className="trial-list-item-text">{item}</span>
                </li>
            ))}
        </ul>
    )
}
export default Upgrade;
