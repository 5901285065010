import { useEffect, useState } from "react";

import AuthLayout from "../../Layout/AuthLayout";
import CustomButton from "../../Components/CustomButton";
import CustomInput from "../../Components/CustomInput";
import CustomToast from "../../Components/CustomToast";

import "./style.css";
import axios from "axios";
import BASEURL from "../../Config/global";
import CryptoJS from 'crypto-js';
import { Eye, EyeSlash } from "../../Assets/svg";
import VerifyModal from "./VerifyModal";
import CountrySelector from "../../Components/CountrySelect";

import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { validateBirthDate } from "../../Services/Common";
countries.registerLocale(enLocale);



const Signup = ({ coupon, closeCouponSignup, newCouponData }) => {

  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [successAlert, setSuccessAlert] = useState(false);
  const [error, setError] = useState({ error: false, text: "" });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [isHousehold, setHousehold] = useState(null);
  const [isHouseholdKey, setHouseholdKey] = useState(null);
  const [passwordEye, setPasswordEye] = useState(true);
  const [showVerifyModal, setShowVerifyModal] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const decryptText = () => {
      setHouseholdKey(params.get('id'));
      const key = 'secret-key'; // Replace with your secret key
      const decryptedId = params.get('id')?.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');
      const decryptedText = CryptoJS.AES.decrypt(decryptedId, key);
      const decrypted = decryptedText?.toString(CryptoJS.enc.Utf8);
      setHousehold(decrypted);
    };
    if (params.get('id')) {
      decryptText();
    }
  }, [])

  const getLocation = async () => {
    try {
      // Get the user's IP address from local storage or an API
      // USA IP address 100.255.255.255
      const ip = localStorage.getItem('ip');
      const token = '75925566402763';
      const response = await fetch(`https://ipinfo.io/${ip}?token=${token}`);
      const data = await response.json();
      const countryCode = data.country;
      const countryName = countries.getName(countryCode, 'en');
      return countryName;
    } catch (error) {
      console.error('Error fetching country data:', error);
    }
  }


  const handleChange = (event) => {
    setError({ error: false, text: "" });
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeCountry = (val) => {
    setError({ error: false, text: "" });
    setFormData({ ...formData, country: val });
  };

  // const validDateDateFormat = () => {
  //   if (formData.date_of_birth && formData.date_of_birth !== '' && formData.date_of_birth.length === 10) {
  //     return true
  //   }

  //   return true
  // };

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    let selectedCountry = '';

    if (formData.date_of_birth && formData.date_of_birth.length > 0) {
      if (!validateBirthDate(formData.date_of_birth)) {
        setLoading(false);
        setError({
          error: true,
          text: "Invalid date format. Please enter a valid date in mm/dd/yyyy format.",
        });
        return
      }
    }

    const today = new Date();
    const givenDate = new Date(formData.date_of_birth);
    const minDate = new Date("01/01/1800");

    if (givenDate > today || minDate > givenDate) {
      setLoading(false);
      setError({
        error: true,
        text: "Please enter valid Birthdate.",
      });
      return
    } 

    if (!formData.first_name || formData.first_name === "") {
      setLoading(false);
      setError({
        error: true,
        text: "First name should not be empty. Please Fill first name",
      });
      return
    }

    if (!formData.last_name || formData.last_name === "") {
      setLoading(false);
      setError({
        error: true,
        text: "Last name should be not empty. Please Fill last name",
      });
      return
    }

    if (!formData.country || formData.country === "") {
      setLoading(false);
      setError({
        error: true,
        text: "Country should be not empty. Please Fill Country",
      });
      return
    }

   
    // if (!formData.zip || formData.zip === "") {
    //   setLoading(false);
    //   setError({
    //     error: true,
    //     text: "Zip should be not empty. Please Fill Zip",
    //   });
    //   return
    // }

    if (!formData.country || formData.country === "") {
      const country = await getLocation();
      console.log(country)
      if (country) {
        if (country === 'United States of America') {
          selectedCountry = "United States of America";
        } else {
          selectedCountry = country;
        }
      } else {
        setLoading(false);
        setError({
          error: true,
          text: "Your country has not been specified",
        });
        return
      }
    } else {
      let checkCountryForUSA = formData.country?.toLowerCase()
      if (checkCountryForUSA === "usa" || checkCountryForUSA === "us" || checkCountryForUSA === "u.s.a" || checkCountryForUSA === "america" || checkCountryForUSA === "united states") {
        selectedCountry = "United States of America"
      } else {
        selectedCountry = formData.country;
      }
    }
    
    if (newCouponData?.countries && !newCouponData?.countries?.includes(selectedCountry)) {
      setLoading(false);
      setError({
        error: true,
        text: "This is not a valid country to continue using this promo code",
      });
      return
    }

    if ((formData.email && formData.email !== "") && formData.first_name && formData.last_name && formData.password && formData.checkPassword) {

      if ((formData.password.length < 6) || (formData.checkPassword.length < 6)) {
        setLoading(false);
        setError({
          error: true,
          text: "Password must contain 6 characters",
        });
      }
      else if (formData.password !== formData.checkPassword) {
        setLoading(false);
        setError({
          error: true,
          text: "Password doesn't match",
        });
      } else {


        let codeIdentity = window.location.pathname.split('/');

        if (codeIdentity.length > 2) {
          codeIdentity = codeIdentity[2];
        } else {
          codeIdentity = null;
        }

        const formDataToSend = new FormData();
        formDataToSend.append("first_name", formData.first_name);
        formDataToSend.append("last_name", formData.last_name);
        formDataToSend.append("email", formData.email?.trim());
        // formDataToSend.append("zip", formData.country || "");
        formDataToSend.append("country", selectedCountry || "");
        formDataToSend.append("state", formData.state || "");
        formDataToSend.append("city", formData.city || "");
        if (formData.date_of_birth) {
          formDataToSend.append("date_of_birth", formData.date_of_birth);
        }
        formDataToSend.append("password", formData.password);
        // formDataToSend.append("phone_number", formData.phone);
        formDataToSend.append("type", "user");
        if (isHousehold) {
          formDataToSend.append("parent", isHousehold);
          formDataToSend.append("key", isHouseholdKey);
        }
        if ((window.location.pathname.includes("promo") || window.location.pathname.includes("promoint"))) {
          formDataToSend.append("user_type", "coupon_lead");
          formDataToSend.append("user_route", window.location.pathname);
          formDataToSend.append("isPromoInt", true);
        } else {
          formDataToSend.append("user_type", "lead");
          // formDataToSend.append("user_route", "signup");
          formDataToSend.append("isPromoInt", false);
        }
        formDataToSend.append('platform', "web");
        formDataToSend.append('code_used_while_signup_code', codeIdentity);
        try {
          const response = await axios.post(
            `${BASEURL}/api/user/signup/`,
            formDataToSend
          );
          setLoading(false);
          if (!response.data.error) {
            localStorage.setItem("user", JSON.stringify(response.data.data[0]));
            localStorage.setItem("userId", JSON.stringify(response.data.data[0].id));
            setUser(JSON.parse(localStorage.getItem("user")));
            setError({ error: false, text: "" });
            if (window.location.pathname.includes("promoint") || window.location.pathname.includes("promo")) {
              verifiedCoupon();
            } else {
              setShowVerifyModal(true);
            }
          } else {
            if (response?.data?.message && typeof response.data.message === 'object') {
              let create_error_message = Object.keys(response.data.message).map(key => response.data.message[key])
              create_error_message = create_error_message.join(' ')
              setError({ error: true, text: create_error_message });
            } else {
              setError({ error: true, text: response.data.message });
            }
          }
        } catch (error) {
          setLoading(false);
          setError({
            error: true,
            text: "An error occurred. Please try again later.",
          });
        }
      }
    } else {
      setLoading(false)
      setError({
        error: true,
        text: "Please fill all required fields",
      });
    }

  };

  const handler = (e) => {
    if (e.key === 'Backspace') {
      if (e.target.value.length == 3) {
        setFormData({ ...formData, 'date_of_birth': e.target.value.slice(0, 2) });
      } else if (e.target.value.length == 6) {
        setFormData({ ...formData, 'date_of_birth': e.target.value.slice(0, 5) });
      } else if (e.target.value.length <= 10 || e.target.value.length == '') {
        setFormData({ ...formData, 'date_of_birth': e.target.value });
      }
    }
  };

  const changeMe = (e) => {
    const input = e.target.value;
    const sanitizedInput = input.replace(/[^0-9/]/g, '');
    const truncatedInput = sanitizedInput.slice(0, 10);
    const matches = truncatedInput.match(/^(\d{0,2})\/?(\d{0,2})\/?(\d{0,})$/);
    if (matches) {
      const [, month, day, year] = matches;
      const isValidMonth = month === '' || (parseInt(month, 10) <= 12);
      const isValidDay = day === '' || (parseInt(day, 10) <= 31);
      const isValidYear = year === '' || (!isNaN(year));
      if (isValidMonth && isValidDay && isValidYear) {
        const formattedInput = [month, day, year].filter(Boolean).join('/');
        setFormData({ ...formData, 'date_of_birth': formattedInput });
      } else {
        console.log("Invalid date");
      }
    } else {
      console.log("Invalid date format");
    }
  };

  const verifiedCoupon = (type) => {
    closeCouponSignup();
  }

  return (
    <>
      <AuthLayout pagename="signup" signup={true}>
        <form>
          <div className="row">
            <div className="col-lg-4">
              <CustomInput
                label="First Name"
                required
                id="fname"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="first_name"
                value={formData.first_name || ""}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-4">
              <CustomInput
                label="Last Name"
                required
                id="lname"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="last_name"
                value={formData.last_name || ""}
                onChange={handleChange}
              />
            </div>
            {/* <div className="col-xl-2 col-lg-6">
              <CustomInput
                label="City"
                id="city"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="city"
                value={formData.city || ""}
                onChange={handleChange}
              />
            </div>
            <div className="col-xl-2 col-lg-6">
              <CustomInput
                label="State"
                id="state"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="state"
                value={formData.state || ""}
                onChange={handleChange}
              />
            </div> */}
            <div className="col-lg-4">
              <CustomInput
                label="Birthdate"
                id="date"
                type="text"
                placeholder="mm/dd/yyyy"
                labelClass="mainLabel"
                inputClass="mainInput place-holder-white"
                name="date_of_birth"
                value={formData?.date_of_birth || ""}
                onChange={changeMe}
                onKeyDown={handler}
              />
            </div>
            <div className="col-lg-6">
              <CustomInput
                label="Email"
                required
                id="email"
                type="email"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="email"
                value={formData.email || ""}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6">
              {/* <CustomInput
                label="Zip"
                required
                id="zip"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="zip"
                value={formData.zip || ""}
                onChange={handleChange}
              /> */}
              {/* <CustomInput
                label="Country"
                id="country"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="country"
                value={formData.country || ""}
                onChange={handleChange}
              /> */}
              <CountrySelector
                name={"Country*"}
                value={formData.country || ""}
                handleChange={handleChangeCountry}
              />
            </div>
            <div className="col-lg-6">
              <div className="inputWrapper">
                <label htmlFor="pass" className="mainLabel">
                  Password*
                </label>

                <div className="passwordWrapper">
                  <input
                    type={passwordEye ? "password" : "text"}
                    required
                    id="pass"
                    name="password"
                    value={formData.password || ""}
                    className={`mainInput passInput`}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inputWrapper">
                <label htmlFor="pass" className="mainLabel">
                  Confirm Password*
                </label>

                <div className="passwordWrapper">
                  <input
                    type={passwordEye ? "password" : "text"}
                    required
                    id="pass"
                    name="checkPassword"
                    value={formData.checkPassword || ""}
                    className={`mainInput passInput`}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <button
                    type="button"
                    className="eyeButton"
                    onClick={() => setPasswordEye(!passwordEye)}
                  >
                    {passwordEye ? <EyeSlash /> : <Eye />}
                  </button>
                </div>
              </div>
            </div>
            {error.error && (
              <div className="col-12">
                <p className="smallText lightColor">{error.text}</p>
              </div>
            )}
          </div>
          <div className="row mt-4 text-center">
            <div className="col-12">
              {
                loading ? (

                  <button className="primaryButton customButton">
                    <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
                  </button>
                )
                  : (

                    <CustomButton
                      variant="primaryButton"
                      text="Sign Up"
                      onClick={handleClick}
                    />
                  )
              }
            </div>
          </div>
        </form>
        <CustomToast
          show={successAlert}
          title={"Successful"}
          message={"Signed Up"}
          close={() => {
            setSuccessAlert(false);
          }}
        />
      </AuthLayout>
      {showVerifyModal && <VerifyModal coupon={coupon} verifiedCoupon={verifiedCoupon} />}
    </>
  );
};

export default Signup;
