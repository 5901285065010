import { useEffect, useState } from "react";
import axios from "axios";
import WebRoutes from "./Routes";
import BASEURL from "./Config/global";
import { isMobileSafari, isSafari, isIOS } from 'react-device-detect'
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import "./Assets/css/style.css";
import "./Assets/css/multi.audio.player.css";
import { Modal } from "react-bootstrap";
import NoInternetConnection from "./Screens/NoInternetConnection";

function useOnlineStatus() {
  const [online, setOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    if (user) {
      getProfile(user.id);
    }
  }, []);
  
  const getProfile = async (id) => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/user/profile/${id}`
      );
      if (!response.data.error) {
        console.log(response?.data.data);
        if (response?.data?.data[0]) {
          const country = response?.data?.data[0].country
          console.log("country", country)
          localStorage.setItem('user_country', JSON.stringify(country))
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    function handleOnline() {
      setOnline(true);
    }

    function handleOffline() {
      setOnline(false);
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return online;
}



function App() {
  // const [online, setOnline] = useState(window.navigator.onLine);
  const online =useOnlineStatus()
  const [ipAddress, setIPAddress] = useState('');
  // state variable holds the state of the internet connection
  // const [isOnline, setOnline] = useState(true);

  // // On initization set the isOnline state.
  // useEffect(() => {
  //   setOnline(navigator.onLine)
  // }, [])

  // // event listeners to update the state 
  // window.addEventListener('online', () => {
  //   setOnline(true)
  // });

  // window.addEventListener('offline', () => {
  //   setOnline(false)
  // });

  useEffect(() => {
    const disableRightClick = (event) => {
      event.preventDefault();
    };

    const handleKeyDown = (event) => {
      if ((event.ctrlKey && event.shiftKey && event.keyCode === 73) ||  // Ctrl + Shift + I
        (event.ctrlKey && event.keyCode === 85)) { // Ctrl + U (view source)
        event.preventDefault();
      }
    };

    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.body.addEventListener('contextmenu', disableRightClick);
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.body.removeEventListener('contextmenu', disableRightClick);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {

    // Disable F12 and Ctrl+Shift+I
    document.addEventListener('keydown', function (event) {
      if (event.keyCode === 123 || (event.ctrlKey && event.shiftKey && event.keyCode === 73)) {
        event.preventDefault();
      }
    });
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error))
  }, []);

  if (ipAddress !== '') {
    localStorage.setItem('ip', ipAddress)
  }

  return (
    <>
      {
        online ? <WebRoutes /> : <NoInternetConnection />
      }
    </>
  );
}

export default App;
