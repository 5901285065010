import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import MainLayout from "./../../Layout/MainLayout";

import "./style.css";
import { getAccessToken } from "../../Util/authHeader";
import axios from "axios";
import BASEURL from "../../Config/global";
import { Membership } from "../../Assets/images";
import { Tick } from "../../Assets/svg";




const PromoPush = ({ handleContinueButton }) => {


    return (
        <>
            <div className="promo-classes fullPageBg d-flex flex-column align-items-start justify-content-start gap-0 h-100" style={{ minHeight: '100vh' }}>
                <div className="container-lg mb-2 mb-sm-4 mt-4 mt-lg-5">
                    <div className="row ">
                        <div className="col-12 text-center">
                            <p className="membership-title text-center w-100">Can’t Sleep? Can’t Relax?</p>
                            <p className="membership-sub-title w-100 mb-0">Waking up in the middle of the Night? <br /> </p>
                            <p className="membership-sub-title w-100 px-5 px-lg-0 mb-0">Having Ruminating Thoughts?  <br className="d-md-none" />Falling Asleep During The Day?</p>
                        </div>
                    </div>
                </div>
                <div className='container-lg membership-container '>
                    <div className="row ms-lg-4">
                    <div className="col-md-6  mt-lg-5  ms-xl-3  ml-md-0">
                                <img src={Membership} className="img-fluid membership-image" />
                        </div>
                        <div className="col-md-6  col-xl-5 align-self-center mt-0 mt-sm-0 ">
                            <div className="row mb-4    mt-md-0">
                                <div className="col-8 col-md-12 col-xl-12 mx-auto">
                                    <h5 className="membership-title my-2 text-center text-md-start text-nowrap">  We are here to Help!</h5>
                                    <div className="ps-md-0 align-list-box ">
                                        <ListItem icon={Tick} data={["Calm Sounds & Music", "Soothing Meditations", "Adult & Children's Stories", "Sleep Articles"]} />
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                            <div className="col-12 col-md-12 mx-auto">
                                    <div className="text-center">
                                        <p className="text-line-height-1 text-center text-md-start mb-2">Relax & Enjoy a Peaceful Night's Sleep</p>
                                        <p className="text-line-height-2  text-center text-md-start fw-light mb-3">FREE Sub-accounts for everyone <br className="d-lg-none" /> in your household* </p>
                                        <div className="d-flex align-items-end justify-content-center justify-content-md-start mt-0">
                                            <button className="primaryButton customButton stop-color py-3" style={{ backgroundColor: '#EFB729', fontSize: '12px', border: 'none' }} onClick={() => handleContinueButton()}>
                                                Continue
                                            </button>
                                        </div>
                                            <p className="py-4 mt-1 trial-list-item-text text-center text-lg-start d-none d-md-block">
                                            *Sub-accounts are  part of active Primary accounts.
                                            </p>
                                            <p  className="py-4 mt-1 trial-list-item-text text-center text-lg-start d-block d-md-none" style={{fontSize:'12px'}}>
                                            *Sub-accounts are  part of active Primary accounts.
                                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


        </>
    );
};
const ListItem = ({ data, icon }) => {
    return (
        <ul className="listing-with-icon">
            {data.map((item, index) => (
                <li className=" " key={index}>
                    <img src={icon} className="tick-style" />
                    <span className="trial-list-item-text text-nowrap">{item}</span>
                </li>
            ))}
        </ul>
    )
}
export default PromoPush;
